import React, { PureComponent } from 'react';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';

import Commission from './Commission';
import CommissionsService from './commissions.service';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class CommissionContainer extends PureComponent {
  state = {
    isLoading: false,
    selectedCommission: null,
    commissionsList: [],
    currentCommissionIds: [],
  };

  componentDidMount() {
    const {
      history: {
        location: { state: redirectState },
      },
    } = this.props;

    this.getCommissionsList(redirectState);
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getCommissionsList = async (redirectState) => {
    try {
      this.toggleLoader(true);
      const commissionsList = await CommissionsService.fetchCommissionsList();

      this.setState({ commissionsList });

      if (commissionsList && commissionsList.length) {
        const currentCommissionId = redirectState ? redirectState.CommissionId : commissionsList[0].CommissionId;

        this.setState({ currentCommissionIds: [currentCommissionId] }, () => {
          this.getCommissionDetailsById(currentCommissionId);
        });
      }
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getCommissionDetailsById = async (CommissionId) => {
    try {
      this.toggleLoader(true);
      const data = await api.getComissionServiceDetails(CommissionId);

      this.setState({ selectedCommission: data.length ? data[0] : null });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onCommissionsChange = (currentCommissionIds) => {
    this.setState({ currentCommissionIds }, () => {
      this.getCommissionDetailsById(currentCommissionIds[0]);
    });
  };

  onCommissionDelete = async () => {
    const { showError, translate } = this.props;
    const { currentCommissionIds } = this.state;

    try {
      this.toggleLoader(true);
      await api.commissionsClientDelete(currentCommissionIds[0]);
      await message.success(translate('grids.comission-sub.successDeleteCommissionMessage'), 2);
      window.location.reload();
    } catch (error) {
      showError(error);
    } finally {
      this.setState({ isLoading: false });
      this.toggleLoader(false);
    }
  };

  render() {
    const { isLoading, commissionsList, currentCommissionIds, selectedCommission } = this.state;
    const { formState } = this.props;

    return (
      <>
        <Commission
          onDropdownSave={this.onCommissionsChange}
          currentCommissionIds={currentCommissionIds}
          commissionsList={commissionsList}
          onCommissionDelete={this.onCommissionDelete}
          {...this.props}
          formState={selectedCommission ? CommissionsService.normalizeCommission(selectedCommission) : formState}
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withRouter(withTranslate(CommissionContainer));
