export const OWNERS_COLUMN_CONFIG = [
  {
    fieldName: 'OwnerId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'ShortName',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerStatus',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-status',
    width: '100',
  },
  {
    fieldName: 'OwnerType',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-type',
    width: '140',
    filter: 'numeric',
  },
];

export const PAYMENT_TYPES_COLUMN_CONFIG = [
  {
    fieldName: 'PaymentTypeId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentType',
    width: '240',
    titleTranslateSlug: 'page.commissionsServices.paymentType',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];
export const PAYMENT_TYPES_OWNER_COL_CONFIG = [
  {
    fieldName: 'PaymentTypeId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentTypeName',
    width: '240',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentType',
    width: '240',
    titleTranslateSlug: 'page.commissionsServices.paymentType',
    filter: 'numeric',
    isShowOnMobile: true,
  },
];
export const PAYMENT_TYPES_CODE_COLUMN_CONFIG = [
  {
    fieldName: 'Code',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentType',
    titleTranslateSlug: 'page.commissionsServices.paymentType',
    width: '150',
    isShowOnMobile: true,
  },
];
export const BASE_SERVICES_COLUMN_CONFIG = [
  {
    fieldName: 'ServiceId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'ServiceName',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Status',
    titleTranslateSlug: 'page.commissionsServices.col-status',
    width: '120',
  },
];

export const ALL_SERVICES_WITHOUT_DELETE = [
  {
    fieldName: 'ServiceId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'StatusName',
    titleTranslateSlug: 'page.commissionsServices.col-status',
    width: '100',
    isShowOnMobile: true,
  },
];

export const SERVICES_COLUMN_CONFIG = [
  ...BASE_SERVICES_COLUMN_CONFIG,
  {
    fieldName: 'Provider',
    titleTranslateSlug: 'page.commissionsServices.col-provider',
    width: '240',
  },
  {
    fieldName: 'ServiceGroup',
    titleTranslateSlug: 'page.commissionsServices.col-group',
    width: '120',
  },
];

export const PROFILES_COLUMN_CONFIG = [
  {
    fieldName: 'ProfileId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'ProfileName',
    titleTranslateSlug: 'page.commissionsServices.col-name',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Visibility',
    titleTranslateSlug: 'page.commissionsServices.col-visibility',
    width: '120',
  },
];

export const COMMISSIONS_COLUMN_CONFIG = [
  {
    fieldName: 'CommissionId',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'owner.column-name',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'UserNamePost',
    titleTranslateSlug: 'grids.comission-sub.priority-performer',
    width: '120',
    isShowOnMobile: false,
  },
  {
    fieldName: 'DatePost',
    titleTranslateSlug: 'grids.commission-terminals.column-date',
    width: '240',
    isShowOnMobile: false,
  },
];

export const COMMISSION_CHANGE_TYPE_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.dealerCommissionsChange.col-name',
    width: '160',
    isShowOnMobile: true,
  },
];

export const PS_ACCOUNT = [
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.bankDetails.label-name',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'BankMfo',
    titleTranslateSlug: 'page.bankDetails.mfi',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'BankAccount',
    titleTranslateSlug: 'page.bankDetails.check',
    width: '160',
    isShowOnMobile: true,
  },
];

export const SERVICE_OWNERS = [
  {
    fieldName: 'PsCustomerId',
    titleTranslateSlug: 'page.bankDetails.col-psId',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.bankDetails.label-name',
    width: '300',
    isShowOnMobile: true,
  },
];

export const CONTRACTS_CONFIG = [
  {
    fieldName: 'TreatyNumber',
    title: '#',
    width: '100',
    isShowOnMobile: true,
  },
  {
    fieldName: 'TreatyDate',
    titleTranslateSlug: 'page.bankDetails.col-date',
    width: '200',
    isShowOnMobile: true,
  },
];

export const SUPPLIERS_CONFIG = [
  {
    fieldName: 'ClientId',
    titleTranslateSlug: 'page.bankDetails.col-clientId',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.bankDetails.label-name',
    width: '320',
    isShowOnMobile: true,
  },
];

export const PAYMENT_SYSTEM_COL_CONFIG = [
  {
    fieldName: 'PaymentOrganization',
    titleTranslateSlug: 'page.bankDetails.col-payment-organization',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'NamePaymentSystem',
    titleTranslateSlug: 'page.bankDetails.col-payment-system-name',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'NamePaymentOrganization',
    titleTranslateSlug: 'page.bankDetails.col-payment-organization-name',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentSystemType',
    titleTranslateSlug: 'page.bankDetails.col-type-payment-system',
    width: '250',
    isShowOnMobile: true,
  },
  {
    fieldName: 'PaymentSystemAttribute',
    titleTranslateSlug: 'page.bankDetails.col-sign-payment-system',
    width: '250',
    isShowOnMobile: true,
  },
];

export const TEMPLATES_DROPDOWN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.templates.template',
    width: '240',
    isShowOnMobile: true,
  },
  {
    fieldName: 'DatePost',
    titleTranslateSlug: 'page.templates.datePost',
    width: '180',
  },
];

export const MANAGER_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.serviceMainProps.col-name',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerName',
    titleTranslateSlug: 'page.serviceMainProps.col-owner',
    width: '160',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Description',
    titleTranslateSlug: 'page.serviceMainProps.col-description',
    width: '200',
    isShowOnMobile: true,
  },
];

export const TERMINALS_LIST = [
  {
    fieldName: 'TerminalId',
    titleTranslateSlug: 'page.managementPaymentTypes.col-terminalId',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'TerminalAddress',
    titleTranslateSlug: 'page.managementPaymentTypes.col-terminalAddress',
    width: '150',
    isShowOnMobile: true,
  },

  {
    fieldName: 'StatusName',
    titleTranslateSlug: 'page.managementPaymentTypes.col-status',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerId',
    titleTranslateSlug: 'page.managementPaymentTypes.col-ownerId',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'OwnerName',
    titleTranslateSlug: 'page.managementPaymentTypes.col-ownerName',
    width: '150',
    isShowOnMobile: true,
  },
];

export const TECHNICAL_PARTNERS = [
  {
    fieldName: 'Id',
    titleTranslateSlug: 'page.technicalPartners.col-id',
    width: '80',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'page.technicalPartners.col-name',
    width: '200',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Edrpo',
    titleTranslateSlug: 'page.technicalPartners.col-EDRPOU',
    width: '110',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Mfo',
    titleTranslateSlug: 'page.technicalPartners.col-MFO',
    width: '100',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Iban',
    titleTranslateSlug: 'page.technicalPartners.col-IBAN',
    width: '220',
    isShowOnMobile: true,
  },
];

// {
//   "profileId": 1,
//   "name": "[Базовый профиль]",
//   "parentId": 0,
//   "level": 1,
//   "userId": 14198,
//   "userName": "V.Naumenko",
//   "datePost": "2023-12-12T13:55:59.310+02:00",
//   "version": 3866
// }

export const MENU_PROFILES = [
  {
    fieldName: 'Id',
    titleTranslateSlug: '',
    width: '150',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: '',
    width: '150',
    isShowOnMobile: true,
  },
];
