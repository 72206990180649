export const ALL_GRID_COLUMNS = [
  'terminalId',
  'name',
  'cityName',
  'terminalAddress',
  'receiptsUsed',
  'designTypeName',
  'dateLastConnectNoUTC',
  'dateLastTransactionNoUTC',
  'cashBoxUsed',
  'cashCount',
  'cashSum',
  'coinBoxUsed',
  'coinBoxQuantity',
  'fiscalNumber',
  'prevTransactionAmountSum',
  'transactionAmountSum',
  'prevTransactionCount',
  'transactionCount',
  'statusState',
  'statusDetails',
  'branch',
  'note',
  'internetTrafficMonth',
  'internetTrafficYesterday',
  'internetTrafficYesterday',
  'mobileOperator',
  'lastScreenTouch',
  'dateLastTerminalMaintenance',
  'mobilePhone',
  'appVersion',
  'moneyTakerCanceled',
];

export const NORMALIZE_FIELD_MAP = new Map([
  ['DateLastConnect', 'dateLastConnectNoUTC'],
  ['DateLastTransaction', 'dateLastTransactionNoUTC'],
  ['DateLastScreenTouch', 'lastScreenTouch'],
]);

export const NORMALIZE_RESPONSE_FIELD_MAP = new Map([
  ['dateLastConnectNoUTC', 'DateLastConnect'],
  ['dateLastTransactionNoUTC', 'DateLastTransaction'],
  ['lastScreenTouch', 'DateLastScreenTouch'],
]);
