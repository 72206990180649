import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';

import { ApiService, StorageService, tabService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';
import CellProgress from './cellProgress';
import CellProgressEncashment from '../encashmentTerminals/cellProgressEncashment';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import { CellDetails } from '../../components/grids';
import { getDateDifferenceString } from '../../utils/getDateDifferenceString';
import { getTransformDateString } from '../../utils/getTransformDateString';
import GqlService from '../../components/grids/gql.service';
import { ALL_GRID_COLUMNS, NORMALIZE_FIELD_MAP } from './constants';
import MonitorActivityService from './monitorActivity.service';
import { lowerCaseFirstLetter } from '../../utils/lowerCaseFirstLetter';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';

const WrappedCellDetails = (props) => <CellDetails {...props} />;

const WrappedCellProgressEncashment = (props) => <CellProgressEncashment {...props} />;

const api = new ApiService();

const aggregate = [
  { field: 'TerminalId', aggregate: 'count', format: 'n0' },
  { field: 'ReceiptsUsed', aggregate: 'sum', format: 'n2' },
  { field: 'CashBoxUsed', aggregate: 'sum', format: 'n0' },
  { field: 'CashCount', aggregate: 'sum', format: 'n0' },
  { field: 'CashSum', aggregate: 'sum', format: 'n0' },
  { field: 'PrevTransactionAmountSum', aggregate: 'sum', format: 'n2' },
  { field: 'TransactionAmountSum', aggregate: 'sum', format: 'n2' },
  { field: 'PrevTransactionCount', aggregate: 'sum', format: 'n0' },
  { field: 'TransactionCount', aggregate: 'sum', format: 'n0' },
  { field: 'MoneyTakerCanceled', aggregate: 'average', format: 'n2' },
];

const statuses = ['all', 'active', 'disabled', 'arested', 'deleted', 'testing'];

class GridMonitorActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('activitymonitor');
    this.state = {
      owners: [],
      isLoading: false,
      data: [],
      statusValue: statuses[0],
      isVisibleTerminalId: false,
      showDeleted: false,
      rowAmount: '50',
      pageValue: 1,
      filtersString: '',
      sortString: '',
      hasNextPage: false,
      hasPreviousPage: false,
      selectIdsString: undefined,
      currentOwners: [],
    };
  }

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '0.5' : '0.25';
    switch (value) {
      case 'активный':
        return `rgba(92, 184, 92, ${opacity})`;
      case 'неактивный':
        return `rgba(240, 173, 78, ${opacity})`;
      case 'неисправен':
      case 'арестован':
        return `rgba(217, 83, 79, ${opacity})`;
      case 'тестируется':
        return `rgba(91, 192, 222, ${opacity})`;
      case 'отключен':
      case 'удален':
        return `rgba(217, 83, 79, ${opacity})`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.StatusState;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  toggleLoader = () => {
    this.setState(({ isLoading }) => {
      return {
        isLoading: !isLoading,
      };
    });
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const owners = await api.owners();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchTerminalListActivityGQL({ selectIdsString });
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  onSpecificFilterByFieldNameSubmit = async (selectIdsString) => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString });
      this.fetchTerminalListActivityGQL({ selectIdsString });
      return;
    }

    this.setState({ selectIdsString }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onSpecificFilterByFieldNameCancel = async () => {
    const { pageValue } = this.state;

    if (pageValue === 1) {
      this.setState({ selectIdsString: undefined });
      this.fetchTerminalListActivityGQL();
      return;
    }

    this.setState({ selectIdsString: undefined }, () => {
      this.setState({
        pageValue: 1,
      });
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchTerminalListActivityGQL({
      currentFieldList:
        list && list.length
          ? list
              .map((field) => NORMALIZE_FIELD_MAP.get(field) || lowerCaseFirstLetter(field))
              .filter((field) => field !== 'selected')
          : null,
    });
  };

  fetchAllTerminalListActivityGQL = async (props) => {
    const { translate } = this.props;
    const { showDeleted, filtersString, sortString, currentOwners } = this.state;

    if (!currentOwners || !currentOwners.length) {
      return;
    }

    const currentFilterString =
      (!props && showDeleted) || (props && !props.selectIdsString && showDeleted)
        ? filtersString
        : `{and:[${filtersString}${
            props && props.selectIdsString ? `, {terminalId:{in:[${props.selectIdsString}]}}` : ``
          }${!showDeleted ? `, {statusId:{neq:7}}` : ``}]}`;

    this.toggleLoader(true);

    try {
      const storageColumns = StorageService.getItem('monitorActivity');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP) : ALL_GRID_COLUMNS);

      const argumentsString = `order: ${
        sortString || '{ terminalId: ASC }'
      },where:{and:[{ownerId:{in:[${currentOwners.join(',')}]}}, ${currentFilterString}]}`;

      const query = `terminalActivityMonitorListAll(${argumentsString}){${currentColumns.join(',')}}`;

      const data = await api.getByGraphQl(query);

      return MonitorActivityService.getAllItemsGQLResponse(data, translate);
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  fetchTerminalListActivityGQL = async (props) => {
    const { translate } = this.props;

    const { showDeleted, rowAmount, pageValue, filtersString, sortString, currentOwners } = this.state;
    if (!currentOwners || !currentOwners.length) {
      return;
    }

    const currentFilterString =
      (!props && showDeleted) || (props && !props.selectIdsString && showDeleted)
        ? filtersString
        : `{and:[${filtersString}${
            props && props.selectIdsString ? `, {terminalId:{in:[${props.selectIdsString}]}}` : ``
          }${!showDeleted ? `, {statusId:{neq:7}}` : ``}]}`;

    this.toggleLoader(true);

    try {
      const storageColumns = StorageService.getItem('monitorActivity');
      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? GqlService.getColumnsFromStorage(storageColumns, NORMALIZE_FIELD_MAP) : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order: ${
        sortString || '{ terminalId: ASC }'
      },where:{and:[{ownerId:{in:[${currentOwners.join(',')}]}}, ${currentFilterString}]}`;

      const query = `terminalActivityMonitorList(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { terminalListActivity, hasPreviousPage, hasNextPage } = MonitorActivityService.getGQLResponse(
        data,
        translate,
      );
      this.setState({ data: terminalListActivity, hasPreviousPage, hasNextPage });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  getHandleExelExportDate = async () => {
    const { selectIdsString } = this.state;

    return await this.fetchAllTerminalListActivityGQL({ selectIdsString });
  };

  showRemoteCallback = () => {
    this.setState(
      ({ showDeleted }) => ({ showDeleted: !showDeleted }),
      () => {
        this.fetchTerminalListActivityGQL();
      },
    );
  };

  onSelectedItems = (currentOwners) => {
    this.setState({ currentOwners }, this.fetchTerminalListActivityGQL);
  };

  onOwners = (owners) => {
    this.setState({ owners }, this.fetchTerminalListActivityGQL);
  };

  onRowClick = (event) => {
    const { data } = this.state;
    if (event.dataItem) {
      data.map((item) => {
        if (item !== event.dataItem) {
          item.selected = false;
          this.forceUpdate();
        }
        return item;
      });
      event.dataItem.selected = !event.dataItem.selected;
      if (event.dataItem.selected === true) {
        this.setState({ selectedItem: event.dataItem.TerminalId });
      } else {
        this.setState({ selectedItem: '' });
      }
    }
    this.forceUpdate();
  };

  onDownload = async () => {
    const { owners } = this.state;

    if (!owners || !owners.length) {
      return;
    }

    try {
      this.toggleLoader();
      const response = await api.terminalMonitorActivityXml(owners[0]);
      const url = window.URL.createObjectURL(new Blob([response[0].Result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'MonitorActivityXml.txt'); //or any other extension
      link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
      link.draggable = true;
      link.classList.add('dragout');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader();
    }
  };

  buildToolbar = () => {
    const { owners, currentOwners } = this.state;
    const { translate } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <GridDropdownWithDeletedOwners
          data={DropdownNormalizersService.normalizeOwners(owners)}
          selectItems={currentOwners}
          onSave={this.onSelectedItems}
          defaultTitle={translate('page.dealerCommissions.selectDealer')}
          labelForAdditonalFilterProps="owner.show-delete"
          isGetDefaultSelectItemsFromStorage
        />
        <Button
          type="primary"
          style={{
            marginTop: '10px',
            marginLeft: 0,
          }}
          onClick={this.onDownload}
        >
          {translate('grids.terminals.button-xml')}
        </Button>
      </Col>
    );
  };

  getShowProperty = (key) => {
    return checkElement(key, this.currentArray);
  };

  getHandlers = () => {
    return [
      {
        title: 'page.dashboard.tab-type-transaction',
        action: (props) => tabService.addTab({ type: 'transaction', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('monitorActivity-tab-type-transaction'),
        // show: true
      },
      {
        title: 'page.dashboard.tab-type-events',
        action: (props) => tabService.addTab({ type: 'events', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('monitorActivity-tab-type-events'),
        // show: true
      },
      {
        title: 'page.certificates.tab-сertificates-issued',
        action: (props) => tabService.addTab({ type: 'issued', dataItem: props.dataItem }),
        dropdown: false,
        show: this.getShowProperty('monitorActivity-tab-сertificates-issued'),
        // show: true
        // show: true
      },
    ];
  };

  render = () => {
    const { isLoading, data, showDeleted, pageValue, hasNextPage, hasPreviousPage, selectIdsString } = this.state;

    const { translate } = this.props;
    return (
      <Grid
        data={data}
        aggregate={aggregate}
        rowRender={this.rowRender}
        onRefresh={() => {
          this.fetchTerminalListActivityGQL({ selectIdsString });
        }}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        selectedField="selected"
        toggleLoader={this.toggleLoader}
        name="monitorActivity"
        permissionName="activitymonitor"
        showRemoteCallback={this.showRemoteCallback}
        isRemoteShow={showDeleted}
        pageChange={this.pageChange}
        handleRowAmountChange={this.handleRowAmountChange}
        pageValue={pageValue}
        setFiltersString={this.setFiltersString}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        setSortingString={this.setSortingString}
        onFieldsConfigChange={this.onFieldsConfigChange}
        normalizeGQLFieldsMap={NORMALIZE_FIELD_MAP}
        onSpecificFilterByFieldNameSubmit={this.onSpecificFilterByFieldNameSubmit}
        onSpecificFilterByFieldNameCancel={this.onSpecificFilterByFieldNameCancel}
        getHandleExelExportDate={this.getHandleExelExportDate}
        multiSelected
        isGQL
        isShowSpecificFilterByFieldName="TerminalId"
      >
        <GridColumn
          field="TerminalId"
          title="#"
          width="100"
          filter="numeric"
          handlers={this.getHandlers()}
          dropdown={checkElement('monitorActivity-isShowDropdownTerminalId', this.currentArray)}
          remoteCommands={true}
          checked={false}
        />

        <GridColumn
          field="Name"
          title="grids.terminals.column-name"
          width="150"
          // locked={true}
          // locked
        />
        <GridColumn field="CityName" title="grids.terminals.column-city" width="150" />

        <GridColumn field="TerminalAddress" title="grids.terminals.column-street" width="380" />

        <GridColumn
          field="ReceiptsUsed"
          title="grids.terminals.column-receipts"
          width="100"
          filter="numeric"
          format="{0:#\%}"
        />

        <GridColumn field="DesignTypeName" title="grids.createTerminal.column-designType" width="150" />

        <GridColumn
          field="DateLastConnect"
          title="grids.terminals.column-lastconnect"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="DateLastTransaction"
          title="grids.terminals.column-lasttransaction"
          width="145"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />

        <GridColumn
          field="CashBoxUsed"
          title="grids.terminals.column-сashBoxUsed"
          width="100"
          filter="numeric"
          cell={CellProgress}
        />

        <GridColumn field="CashCount" title="grids.terminals.column-cashCount" width="100" filter="numeric" />

        <GridColumn field="CashSum" title="grids.terminals.column-cashSum" width="100" filter="numeric" />

        <GridColumn
          field="MoneyTakerCanceled"
          title="grids.terminals.column-moneyTakerCanceled"
          width="100"
          filter="numeric"
          format="{0:#\%}"
        />

        <GridColumn
          field="CoinBoxUsed"
          title="grids.encashment.column-coinBoxUsed"
          width="200"
          filter="numeric"
          cell={WrappedCellProgressEncashment}
        />

        <GridColumn
          field="CoinBoxQuantity"
          title="grids.encashment.column-coinBoxQuantity"
          width="200"
          filter="numeric"
        />

        <GridColumn field="FiscalNumber" title="grids.createTerminal.column-fiscalNumber" width="150" />
        {/* </GridColumn> */}
        {/* <GridColumn title="grids.terminals.column-turnovers"> */}
        <GridColumn
          field="PrevTransactionAmountSum"
          title="grids.terminals.column-turnoversYesterday"
          width="100"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="TransactionAmountSum"
          title="grids.terminals.column-turnoversToday"
          width="100"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="PrevTransactionCount"
          title="grids.terminals.column-paymentsYesterday"
          width="100"
          filter="numeric"
        />
        <GridColumn
          field="TransactionCount"
          title="grids.terminals.column-paymentsToday"
          width="100"
          filter="numeric"
        />

        <GridColumn field="StatusState" title="grids.terminals.column-state" width="145" />

        <GridColumn
          field="StatusDetails"
          title="grids.terminals.column-details"
          width="98"
          cell={WrappedCellDetails}
          tooltip={true}
        />

        <GridColumn field="Branch" title="grids.terminals.column-branch" width="145" />

        <GridColumn field="Note" title="page.terminalSettings.field-notes" width="145" />

        <GridColumn
          field="InternetTrafficMonth"
          title="grids.terminals.column-internet-traffic-month"
          width="145"
          filter="numeric"
        />

        <GridColumn
          field="InternetTrafficYesterday"
          title="grids.terminals.column-internet-traffic-yesterday"
          width="145"
          filter="numeric"
        />

        <GridColumn field="MobileOperator" title="grids.terminals.column-mobile-operator" width="145" />

        <GridColumn field="DateLastScreenTouch" title="grids.terminals.column-activity" width="150" />

        <GridColumn
          field="DateLastTerminalMaintenance"
          title="grids.terminals.column-lastMaintenance"
          width="150"
          cell={({ dataItem: { DateLastTerminalMaintenance } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DateLastTerminalMaintenance)}</td>
          )}
          formatFilterCellData={(DateLastTerminalMaintenance) => getTransformDateString(DateLastTerminalMaintenance)}
        />

        <GridColumn field="MobilePhone" title="grids.terminals.column-simCardNumber" width="150" />
      </Grid>
    );
  };
}

export default withRouter(withTranslate(GridMonitorActivity));
