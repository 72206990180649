class CommissionsServicesService {
  normalizeCommissionsClientServices(commissionsClientServices) {
    if (!commissionsClientServices || !commissionsClientServices.length) {
      return [];
    }

    return commissionsClientServices.map((commissionsClientService) => ({
      ...commissionsClientService,
      Id: `${commissionsClientService.ServiceId}-${commissionsClientService.CommissionId}-${commissionsClientService.PaymentType}`,
    }));
  }

  getPercent(countInProcess, countTotal, translate) {
    const percent = Math.round((1 - countInProcess / countTotal) * 100);

    const processedMessagePrefix =
      percent > 5
        ? `${translate('page.commissionsServices.processed')} ${percent}%`
        : `${translate('page.commissionsServices.processedLess')} 5%`;

    return `${processedMessagePrefix} ${translate('page.commissionsServices.processedMessagePostfix')}`;
  }
}

export default new CommissionsServicesService();
