import React, { PureComponent } from 'react';

import ManageMerchantCallbacksGrid from './ManageMerchantCallbacksGrid';
import PageTabs from '../../components/tabber/tabber';

class ManageMerchantCallbacks extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-manageMerchantCallbacks',
          content: <ManageMerchantCallbacksGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} />;
  }
}

export default ManageMerchantCallbacks;
