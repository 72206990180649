import React, { PureComponent } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { withTranslate } from '../../../contexts/localContext';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import {
  PAYMENT_TYPES_COLUMN_CONFIG,
  OWNERS_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
  PROFILES_COLUMN_CONFIG,
} from '../../../core/constants/configs.const';
import LoadingPanel from '../../../components/loader';
import Grid from '../../../components/grids/baseGrid';
import { tabService } from '../../../services';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import DeleteModal from '../../../components/DeleteModal';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

import './CommissionsServices.scss';
import InfoModal from '../../../components/InfoModal/InfoModal';

class CommissionsServicesGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('monitoringCommissionServices');
  }

  renderSelects = () => {
    const {
      translate,
      onOwnerChange,
      paymentTypes,
      selectPaymentTypes,
      profiles,
      onPaymentTypesSave,
      onProfileSave,
      selectProfiles,
      services,
      selectServices,
      onServiceSave,
      owners,
      selectOwner,
    } = this.props;

    return (
      <Col span={24} xl={20} xxl={16} className="CommissionsServices-selects">
        <Col span={24} sm={16} lg={12} className="CommissionsServices-row">
          <Col span={4} className="CommissionsServices-label">
            {translate('page.commissionsServices.dealer')}
          </Col>

          <Col span={18} offset={1}>
            <GridDropdown
              data={owners}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={[selectOwner]}
              onSave={onOwnerChange}
              isSingle
              isGetDefaultSelectItemsFromStorage
            />
          </Col>
        </Col>

        <Col span={24} sm={16} lg={12} className="CommissionsServices-row">
          <Col span={4} className="CommissionsServices-label">
            {translate('page.commissionsServices.paymentType')}
          </Col>

          <Col span={18} offset={1}>
            <GridDropdown
              data={paymentTypes}
              colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
              selectItems={selectPaymentTypes}
              onSave={onPaymentTypesSave}
              defaultTitle={translate('page.commissionsServices.choosePaymentType')}
            />
          </Col>
        </Col>

        <Col span={24} sm={16} lg={12} className="CommissionsServices-row">
          <Col span={4} className="CommissionsServices-label">
            {translate('page.commissionsServices.profile')}
          </Col>

          <Col span={18} offset={1}>
            <GridDropdown
              data={profiles}
              colConfig={PROFILES_COLUMN_CONFIG}
              selectItems={selectProfiles}
              onSave={onProfileSave}
              defaultTitle={translate('page.commissionsServices.chooseProfile')}
              isSingle
            />
          </Col>
        </Col>

        <Col span={24} sm={16} lg={12} className="CommissionsServices-row">
          <Col span={4} className="CommissionsServices-label">
            {translate('page.commissionsServices.service')}
          </Col>

          <Col span={18} offset={1}>
            <GridDropdown
              data={services}
              colConfig={SERVICES_COLUMN_CONFIG}
              selectItems={selectServices}
              onSave={onServiceSave}
              defaultTitle={translate('page.commissionsServices.chooseService')}
            />
          </Col>
        </Col>
      </Col>
    );
  };

  renderCopyButton = () => {
    const { isLastOperationEnded, translate, onOpenCopyCommissionTab } = this.props;
    const Wrapper = isLastOperationEnded ? React.Fragment : Tooltip;

    return (
      <Wrapper
        {...(!isLastOperationEnded ? { title: translate('page.commissionsServices.copyingProcessNotCompleted') } : {})}
      >
        <Button type="primary" onClick={onOpenCopyCommissionTab} disabled={!isLastOperationEnded}>
          {translate('core.buttonTitles.copy')}
        </Button>
      </Wrapper>
    );
  };

  renderToolbar = () => {
    const {
      translate,
      getCommissionsClientServices,
      selectPaymentTypes,
      selectProfiles,
      selectServices,
      selectOwner,
      paymentTypes,
      setDialogType,
      selectedItems,
      getCopyCommissionResult,
    } = this.props;

    const isSubmitButtonActive =
      (selectOwner || selectOwner === 0) &&
      selectPaymentTypes.length &&
      (selectPaymentTypes[0] || selectPaymentTypes[0] === 0) &&
      selectProfiles.length &&
      (selectProfiles[0] || selectProfiles[0] === 0) &&
      selectServices.length &&
      (selectServices[0] || selectServices[0] === 0);

    return (
      <Row className="CommissionsServices-toolbar">
        {this.renderSelects()}
        <Col span={23} sm={15} lg={23} xl={1} className="CommissionsServices-searchAction">
          {checkElement('monitoringCommissionServices-searchCommission', this.currentArray) && (
            <Button type="primary" onClick={getCommissionsClientServices} disabled={!isSubmitButtonActive}>
              {translate('core.buttonTitles.search')}
            </Button>
          )}
        </Col>
        <Col offset={2} span={22} className="CommissionsServices-modalActions">
          {checkElement('monitoringCommissionServices-addCommission', this.currentArray) && (
            <Button
              type="primary"
              onClick={() =>
                tabService.addTab({
                  type: 'choiceCommission',
                  dataItem: {
                    selectedServices: selectServices,
                    paymentTypes,
                    selectProfiles,
                  },
                })
              }
              disabled={!isSubmitButtonActive}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}
          {checkElement('monitoringCommissionServices-deleteCommission', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                setDialogType('deleteCommission');
              }}
              disabled={!selectedItems.length}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
          {checkElement('monitoringCommissionServices-copyCommission', this.currentArray) && (
            <>
              {this.renderCopyButton()}
              <Button
                type="primary"
                onClick={getCopyCommissionResult}
                className="CommissionsServices-showResultOfCopyingAction"
              >
                {translate('core.buttonTitles.resultOfCopying')}
              </Button>
            </>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const {
      isLoading,
      commissionsClientServices,
      translate,
      paymentTypes,
      redirectToCommissionPage,
      dialogType,
      setDialogType,
      deleteCommission,
      getSelectsData,
      selectedItems,
      onSelect,
      copyCommissionProcessPercentMessage,
    } = this.props;

    return (
      <>
        <Grid
          data={commissionsClientServices}
          toolbar={this.renderToolbar()}
          isLoading={isLoading}
          onRefresh={getSelectsData}
          onSelect={onSelect}
          className="CommissionsServices-grid"
          name="monitoringCommissionServices-grid"
          permissionName="monitoringCommissionServices"
          fieldForSelection="Id"
          multiSelected
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            showAllSelected
          />

          <GridColumn
            field="ProfileId"
            title="grids.commission-profiles.column-profileId"
            width="150"
            filter="numeric"
          />

          <GridColumn field="ProfileName" title="page.commissionsServices.profile" width="120" filter="numeric" />

          <GridColumn
            field="ServiceId"
            title="page.commissionsServices.col-serviceNumber"
            width="150"
            filter="numeric"
          />

          <GridColumn field="ServiceName" title="page.commissionsServices.col-serviceName" width="240" />

          <GridColumn
            field="CommissionId"
            title="page.commissionsServices.col-commissionNumber"
            width="150"
            filter="numeric"
          />

          <GridColumn
            field="CommissionName"
            title="page.commissionsServices.col-commissionName"
            width="240"
            onClick={
              checkElement('monitoringCommissionServices-pushToCommissions', this.currentArray)
                ? (cellProps) => redirectToCommissionPage(cellProps)
                : null
            }
          />

          <GridColumn
            field="DateStart"
            title="page.commissionsServices.col-beginningDate"
            width="160"
            cell={({ dataItem: { DateStart } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateStart)}</td>
            )}
            formatFilterCellData={(DateStart) => getTransformDateString(DateStart)}
          />

          <GridColumn
            field="DateEnd"
            title="page.commissionsServices.col-expirationDate"
            width="160"
            cell={({ dataItem: { DateEnd } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateEnd)}</td>
            )}
            formatFilterCellData={(DateEnd) => getTransformDateString(DateEnd)}
          />

          <GridColumn
            field="Active"
            title="page.commissionsServices.col-isEnabled"
            width="120"
            cell={({ dataItem: { Active } }) => (
              <td style={{ textAlign: 'center' }}>
                {Active ? translate('page.commissionsServices.yes') : translate('page.commissionsServices.no')}
              </td>
            )}
            formatFilterCellData={(Active) =>
              Active ? translate('page.commissionsServices.yes') : translate('page.commissionsServices.no')
            }
          />

          <GridColumn field="ClientCommissionRule" title="page.commissionsServices.col-rule" width="420" />

          <GridColumn field="Priority" title="page.commissionsServices.col-priority" width="120" />

          <GridColumn
            field="DatePost"
            title="page.commissionsServices.col-dateOfChange"
            width="160"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />

          <GridColumn
            field="PaymentType"
            title="page.commissionsServices.col-paymentTool"
            width="170"
            cell={({ dataItem: { PaymentType } }) => (
              <td style={{ textAlign: 'center' }}>
                {paymentTypes.find(({ PaymentTypeId }) => PaymentTypeId === PaymentType).PaymentType}
              </td>
            )}
            formatFilterCellData={(PaymentType) =>
              paymentTypes.find(({ PaymentTypeId }) => PaymentTypeId === PaymentType).PaymentType
            }
          />

          <GridColumn field="UserNamePost" title="page.commissionsServices.col-executor" width="140" />
        </Grid>
        {<DeleteModal />}
        {isLoading && <LoadingPanel />}
        {dialogType === 'deleteCommission' && (
          <DeleteModal
            visible={dialogType === 'deleteCommission'}
            closeModal={() => {
              setDialogType('');
            }}
            submitCallback={() => {
              deleteCommission(selectedItems);
            }}
            deleteContent={translate('page.commissionsServices.deleteServiceQuestion')}
            messageSuccess={translate('page.commissionsServices.deleteServiceSuccess')}
          />
        )}
        {dialogType === 'copyCommissionResult' && (
          <InfoModal
            closeModal={() => {
              setDialogType('');
            }}
            visible={dialogType === 'copyCommissionResult'}
            modalTitle={translate('core.buttonTitles.resultOfCopying')}
            content={copyCommissionProcessPercentMessage}
          />
        )}
      </>
    );
  }
}

export default withTranslate(CommissionsServicesGrid);
