import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import CommissionsServicesGrid from './CommissionsServicesGrid';
import { ApiService, tabService, StorageService } from '../../../services';
import CommissionsServicesService from './commissionsServices.service';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { withTranslate } from '../../../contexts/localContext';
import { ALL_GRID_COLUMNS } from './constants';
import GqlService from '../../../components/grids/gql.service';

const api = new ApiService();

class CommissionsServicesGridContainer extends PureComponent {
  state = {
    paymentTypes: [],
    profiles: [],
    services: [],
    selectedOwners: [1],
    selectPaymentTypes: [],
    selectProfile: [],
    selectServices: [],
    commissionsClientServices: [],
    dialogType: '',
    selectedItems: [],
    owners: [],
    isLastOperationEnded: true,
    copyCommissionResultList: [],
    currentBatchId: null,
    copyCommissionProcessPercentMessage: '',
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    loadingStack: [],
    isIgnoreChangePageValue: false,
  };

  setFiltersString = (filtersString) => {
    this.setState({ filtersString });
  };

  setSortingString = (sortString) => {
    this.setState({ sortString });
  };

  async componentDidMount() {
    window.addEventListener('beforeunload', this.saveSelectValues);

    await this.fetchByQueryPath();
  }

  onBaseDataChange = () => {
    this.setState({ commissionsClientServices: [], pageValue: 1, isIgnoreChangePageValue: true });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, isIgnoreChangePageValue } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (isIgnoreChangePageValue) {
      this.setState({ isIgnoreChangePageValue: false });
      return;
    }

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.getCommissionsClientServicesGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  saveSelectValues = () => {
    const { selectedOwners, selectPaymentTypes, selectServices, selectProfile } = this.state;
    console.log('selectedOwners', selectedOwners);
    StorageService.setItem('externalCommissionsServicesGridOwner', selectedOwners);
    StorageService.setItem('externalCommissionsServicesPaymentTypes', selectPaymentTypes);
    StorageService.setItem('externalCommissionsServicesGridServices', selectServices);
    StorageService.setItem('externalCommissionsServicesGridProfile', selectProfile);
  };

  componentWillUnmount() {
    this.saveSelectValues();
    window.removeEventListener('beforeunload', this.saveSelectValues);
  }

  fetchByQueryPath = async () => {
    const {
      history: {
        location: { state: redirectState },
      },
    } = this.props;

    if (redirectState) {
      await this.searchDataAfterPush(redirectState);
    } else {
      this.setState(
        {
          selectedOwners: StorageService.getItem('externalCommissionsServicesGridOwner') || [1],
          selectPaymentTypes: StorageService.getItem('externalCommissionsServicesPaymentTypes') || [],
          selectServices: StorageService.getItem('externalCommissionsServicesGridServices') || [],
          selectProfile: StorageService.getItem('externalCommissionsServicesGridProfile') || [],
        },
        async () => {
          await this.getSelectsData();
        },
      );
    }
  };

  searchDataAfterPush = async (redirectState) => {
    const { ProfileId, OwnerId, PaymentType } = redirectState;

    await this.setState({ selectedOwners: [OwnerId] });
    await this.getSelectsData();

    this.setState(
      ({ services }) => {
        return {
          selectProfile: [ProfileId],
          selectPaymentTypes: [PaymentType],
          selectServices: services.length ? services.map(({ ServiceId }) => ServiceId) : [],
        };
      },
      () => {
        this.getCommissionsClientServicesGQL();
      },
    );
  };

  toggleLoader = (value) => {
    this.setState(({ loadingStack }) => {
      const currentLoadingStack = [...loadingStack];

      if (value) {
        currentLoadingStack.push(value);
        return { loadingStack: currentLoadingStack };
      }

      currentLoadingStack.pop();
      return { loadingStack: currentLoadingStack };
    });
  };

  getPaymentType = async () => {
    try {
      this.toggleLoader(true);
      const data = await api.getPaymentTypeList();

      await this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getOwners = async () => {
    try {
      this.toggleLoader(true);
      const data = await api.owners();

      await this.setState({ owners: data });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getProfiles = async () => {
    const { selectedOwners } = this.state;
    const { translate } = this.props;

    try {
      this.toggleLoader(true);
      const data = await api.postProfileList({
        ownerId: [...selectedOwners],
      });

      await this.setState({
        profiles: data && data.length ? DropdownNormalizersService.normalizeProfiles(data, translate) : [],
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  getServices = async () => {
    const { selectedOwners } = this.state;

    try {
      this.toggleLoader(true);
      const data = await api.getServiceDictionary({
        ownerId: [0],
      });

      await this.setState({ services: data && data.length ? DropdownNormalizersService.normalizeServices(data) : [] });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      this.toggleLoader(false);
    }
  };

  getSelectsData = async () => {
    await this.getOwners();
    await this.getPaymentType();
    await this.getProfiles();
    await this.getServices();
    await this.getCopyCommissionStatus();
  };

  getCopyCommissionStatus = async () => {
    const { translate } = this.props;
    try {
      this.toggleLoader(true);
      const copyCommissionStatusList = await api.commissionsClientReferenceCopyRequests();
      if (!copyCommissionStatusList || !copyCommissionStatusList.length) {
        this.setState({ isLastOperationEnded: true });

        return;
      }

      const lastBatch = copyCommissionStatusList.sort(
        ({ DateCreated: prevDateCreated }, { DateCreated: nextDateCreated }) =>
          new Date(nextDateCreated) - new Date(prevDateCreated),
      )[0];

      if (!lastBatch.CountInProcess || !lastBatch.CountTotal) {
        this.setState({ isLastOperationEnded: true, currentBatchId: lastBatch });

        return;
      }

      await this.setState({
        isLastOperationEnded: false,
        copyCommissionProcessPercentMessage: CommissionsServicesService.getPercent(
          lastBatch.CountInProcess,
          lastBatch.CountTotal,
          translate,
        ),
      });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  getCommissionsClientServicesGQL = async () => {
    const {
      selectedOwners,
      selectProfile,
      selectPaymentTypes,
      selectServices,
      rowAmount,
      pageValue,
      filtersString,
      sortString,
    } = this.state;

    if (!selectedOwners.length || !selectProfile.length || !selectPaymentTypes.length || !selectServices.length) {
      return;
    }

    try {
      await this.toggleLoader(true);
      const storageServiceColumns = StorageService.getItem('monitoringCommissionServices-grid');
      const currentColumns = storageServiceColumns
        ? GqlService.getColumnsFromStorage(storageServiceColumns)
        : ALL_GRID_COLUMNS;

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},
    order: ${sortString || '{commissionId: ASC}'}
    where: {and: [
    {profileId: {in: [${selectProfile}]}},
    {paymentType: {in: [${selectPaymentTypes}]}},
    {serviceId: {in: [${selectServices}]}},
    ${filtersString}
    ]}`;
      const query = `commissionClientServiceList(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;
      const data = await api.getByGraphQl(query);
      const { commissionsClientServices, hasNextPage, hasPreviousPage } =
        CommissionsServicesService.getGQLResponse(data);
      await this.setState({
        commissionsClientServices,
        hasNextPage,
        hasPreviousPage,
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwnerChange = (selectedOwners) => {
    this.setState({ selectedOwners: selectedOwners }, async () => {
      await this.onBaseDataChange();
      await this.setState({ selectProfile: [] });
      await this.getProfiles();
    });
  };

  onPaymentTypesSave = (selectPaymentTypes) => {
    this.setState({ selectPaymentTypes }, () => {
      this.onBaseDataChange();
    });
  };

  onProfileSave = (profiles) => {
    this.setState({ selectProfile: profiles }, () => {
      this.onBaseDataChange();
    });
  };

  onServiceSave = (selectServices) => {
    this.setState({ selectServices }, () => {
      this.onBaseDataChange();
    });
  };

  deleteCommission = async (selectItems) => {
    const { paymentTypes } = this.state;
    try {
      await this.toggleLoader(true);

      const model = selectItems.map(({ ServiceId, CommissionId, PaymentType, ProfileId }) => ({
        CommissionId,
        ServiceId,
        ProfileId,
        paymentTypesList: [PaymentType],
      }));

      const commissionActionsList = await api.deleteCommissionsClientReference(model);

      await this.getCommissionsClientServicesGQL();

      await tabService.addTab({
        type: 'commissionActionResults',
        dataItem: {
          commissionActionResults: commissionActionsList,
          paymentTypes,
        },
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  redirectToCommissionPage = ({ dataItem: { CommissionId } }) => {
    const { history } = this.props;

    console.log('CommissionId', CommissionId);

    return history.push({
      pathname: '/pages/managementCommissions/commission',
      state: { CommissionId },
    });
  };

  setDialogType = (dialogType) => {
    this.setState({ dialogType });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  getCopyCommissionResult = async () => {
    const { currentBatchId, paymentTypes } = this.state;

    this.toggleLoader(true);

    try {
      if (!currentBatchId || !currentBatchId.BatchGuid) {
        await this.getCopyCommissionStatus();
        this.setDialogType('copyCommissionResult');
        return;
      }

      const commissionsActionList = await api.commissionsClientReferenceCopyResult(currentBatchId.BatchGuid);

      await tabService.addTab({
        type: 'commissionActionResults',
        dataItem: {
          commissionActionResults: commissionsActionList && commissionsActionList.length ? commissionsActionList : [],
          paymentTypes,
        },
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  onOpenCopyCommissionTab = () => {
    const { services, paymentTypes, profiles } = this.state;

    tabService.addTab({
      type: 'copyCommission',
      dataItem: {
        services,
        paymentTypes,
        profiles,
      },
    });
  };

  onFieldsConfigChange = () => {
    this.getCommissionsClientServicesGQL();
  };

  render() {
    const {
      loadingStack,
      profiles,
      paymentTypes,
      selectPaymentTypes,
      selectProfile,
      services,
      selectServices,
      commissionsClientServices,
      selectedOwners,
      dialogType,
      selectedItems,
      owners,
      isLastOperationEnded,
      currentBatchId,
      copyCommissionProcessPercentMessage,
      pageValue,
      hasNextPage,
      hasPreviousPage,
    } = this.state;

    return (
      <CommissionsServicesGrid
        onOwnerChange={this.onOwnerChange}
        onPaymentTypesSave={this.onPaymentTypesSave}
        onProfileSave={this.onProfileSave}
        onServiceSave={this.onServiceSave}
        getCommissionsClientServices={this.getCommissionsClientServicesGQL}
        profiles={profiles}
        paymentTypes={paymentTypes}
        services={services}
        getSelectsData={this.getSelectsData}
        onSelect={this.handleSelectionChange}
        deleteCommission={this.deleteCommission}
        redirectToCommissionPage={this.redirectToCommissionPage}
        setDialogType={this.setDialogType}
        isLoading={!!loadingStack.length}
        selectPaymentTypes={selectPaymentTypes}
        selectProfiles={selectProfile}
        selectServices={selectServices}
        selectedOwners={selectedOwners}
        currentBatchId={currentBatchId}
        handleSelectChange={this.handleSelectChange}
        dialogType={dialogType}
        selectedItems={selectedItems}
        owners={DropdownNormalizersService.normalizeOwners(owners)}
        isLastOperationEnded={isLastOperationEnded}
        getCopyCommissionResult={this.getCopyCommissionResult}
        copyCommissionProcessPercentMessage={copyCommissionProcessPercentMessage}
        onOpenCopyCommissionTab={this.onOpenCopyCommissionTab}
        handleRowAmountChange={this.handleRowAmountChange}
        pageChange={this.pageChange}
        pageValue={pageValue}
        commissionsClientServices={commissionsClientServices}
        setFiltersString={this.setFiltersString}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        setSortingString={this.setSortingString}
        onFieldsConfigChange={this.onFieldsConfigChange}
      />
    );
  }
}

export default withRouter(withTranslate(CommissionsServicesGridContainer));
