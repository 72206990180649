import React, { PureComponent } from 'react';
import { Button, Checkbox, Col, Radio, Row } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import {
  PAYMENT_TYPES_COLUMN_CONFIG,
  PROFILES_COLUMN_CONFIG,
  SERVICES_COLUMN_CONFIG,
} from '../../../core/constants/configs.const';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { DISABLE_AVAILABILITY, DROPDOWNS_MULTI_CHOICE_AVAILABILITY, DROPDOWNS_ORDERS } from './copyCommission.const';
import LoadingPanel from '../../../components/loader';
import InfoModal from '../../../components/InfoModal';

import './CopyCommission.scss';
import { MOBILE_BREAKPOINT } from './copyCommission.const';

class CopyCommission extends PureComponent {
  constructor(props) {
    super(props);
    this.isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
  }

  renderRadioGroup = () => {
    const {
      formState: { copyCommissionType },
      onRadioChange,
      translate,
    } = this.props;

    return (
      <Row className="CopyCommission-radioGroup">
        <Radio.Group value={copyCommissionType} onChange={onRadioChange}>
          <Col span={24} className="CommissionDetails-radio">
            <Radio value="SERVICE_TO_SERVICE_TYPE">
              {' '}
              {translate('page.commissionsServices.fromServiceToService')}:
            </Radio>
          </Col>

          <Col span={24} className="CommissionDetails-radio">
            <Radio value="PROFILE_TO_PROFILE_TYPE"> {translate('page.commissionsServices.fromProfileProfile')}:</Radio>
          </Col>

          <Col span={24} className="CommissionDetails-radio">
            <Radio value="PAYMENT_TOOL_TO_PAYMENT_TOOL_TYPE">
              {' '}
              {translate('page.commissionsServices.fromPaymentInstrument')}:
            </Radio>
          </Col>
        </Radio.Group>
      </Row>
    );
  };

  renderRightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 42.67">
      <path d="M63.22 19.57 44.5.78a2.67 2.67 0 0 0-3.78 3.77l14.07 14.12H2.67a2.67 2.67 0 1 0 0 5.33H55L40.73 38.11a2.67 2.67 0 1 0 3.76 3.78l18.72-18.54a2.67 2.67 0 0 0 0-3.78Z" />
    </svg>
  );

  renderDownArrow = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-arrow-down"
    >
      <path d="M12 5v14M19 12l-7 7-7-7" />
    </svg>
  );

  renderSelectsRow = (
    rowTitleSlug,
    dropdownData,
    dropdownConfig,
    dropdownDefaultTitle,
    sourceDropdownProps,
    targetDropdownProps,
  ) => {
    const { sourceDropdownValue, sourceDropdownSaveCallback, isSourceDropdownSingle, sourceDropdownErrorMessage } =
      sourceDropdownProps;

    const {
      targetDropdownValue,
      targetDropdownSaveCallback,
      isTargetDropdownDisable,
      isTargetDropdownSingle,
      targetDropdownErrorMessage,
    } = targetDropdownProps;

    return (
      <>
        <Col span={24} lg={3} className="CommissionDetails-titleRow">
          {rowTitleSlug}
        </Col>

        <Col span={24} lg={9}>
          <GridDropdown
            data={dropdownData}
            colConfig={dropdownConfig}
            selectItems={sourceDropdownValue}
            onSave={sourceDropdownSaveCallback}
            defaultTitle={dropdownDefaultTitle}
            disabled={false}
            isSingle={isSourceDropdownSingle}
            maximumNumberSelected={10}
          />
          {sourceDropdownErrorMessage && (
            <Col span={24} className="CommissionDetails-errorMessage">
              {sourceDropdownErrorMessage}
            </Col>
          )}
        </Col>

        <Col span={24} lg={3} className="CommissionDetails-iconRow">
          <div className="CommissionDetails-icon">
            {this.isMobile ? this.renderDownArrow() : this.renderRightArrow()}
          </div>
        </Col>

        <Col span={24} lg={9}>
          <GridDropdown
            data={dropdownData}
            colConfig={dropdownConfig}
            selectItems={targetDropdownValue}
            onSave={targetDropdownSaveCallback}
            defaultTitle={isTargetDropdownDisable ? '' : dropdownDefaultTitle}
            disabled={isTargetDropdownDisable}
            isSingle={isTargetDropdownSingle}
            maximumNumberSelected={10}
          />
          {targetDropdownErrorMessage && (
            <Col span={24} className="CommissionDetails-errorMessage">
              {targetDropdownErrorMessage}
            </Col>
          )}
        </Col>
      </>
    );
  };

  renderSelects = () => {
    const {
      formState: {
        sourceProfileDropdown,
        targetProfileDropdown,
        copyCommissionType,
        sourceServiceDropdown,
        targetServiceDropdown,
        sourcePaymentTypeDropdown,
        targetPaymentTypeDropdown,
      },
      services,
      paymentTypes,
      profiles,
      dropdownSaveCallbacks,
      translate,
      formErrors,
    } = this.props;

    const sourceProfileDropdownProps = {
      sourceDropdownValue: sourceProfileDropdown,
      sourceDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].sourceProfileDropdownSave,
      isSourceDropdownSingle: DROPDOWNS_MULTI_CHOICE_AVAILABILITY.SOURCE_DROPDOWNS[copyCommissionType].profileDropdown,
      sourceDropdownErrorMessage: formErrors.sourceProfileDropdown,
    };

    const targetProfileDropdownProps = {
      targetDropdownValue: targetProfileDropdown,
      targetDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].targetProfileDropdownSave,
      isTargetDropdownSingle: DROPDOWNS_MULTI_CHOICE_AVAILABILITY.TARGET_DROPDOWNS[copyCommissionType].profileDropdown,
      isTargetDropdownDisable: DISABLE_AVAILABILITY[copyCommissionType].profileDropdown,
      targetDropdownErrorMessage: formErrors.targetProfileDropdown,
    };

    const sourceServiceDropdownProps = {
      sourceDropdownValue: sourceServiceDropdown,
      sourceDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].sourceServiceDropdownSave,
      isSourceDropdownSingle: DROPDOWNS_MULTI_CHOICE_AVAILABILITY.SOURCE_DROPDOWNS[copyCommissionType].serviceDropdown,
      sourceDropdownErrorMessage: formErrors.sourceServiceDropdown,
    };

    const targetServiceDropdownProps = {
      targetDropdownValue: targetServiceDropdown,
      targetDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].targetServiceDropdownSave,
      isTargetDropdownSingle: DROPDOWNS_MULTI_CHOICE_AVAILABILITY.TARGET_DROPDOWNS[copyCommissionType].serviceDropdown,
      isTargetDropdownDisable: DISABLE_AVAILABILITY[copyCommissionType].serviceDropdown,
      targetDropdownErrorMessage: formErrors.targetServiceDropdown,
    };

    const sourcePaymentToolDropdownProps = {
      sourceDropdownValue: sourcePaymentTypeDropdown,
      sourceDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].sourcePaymentTypeDropdownSave,
      isSourceDropdownSingle:
        DROPDOWNS_MULTI_CHOICE_AVAILABILITY.SOURCE_DROPDOWNS[copyCommissionType].paymentTypeDropdown,
      sourceDropdownErrorMessage: formErrors.sourcePaymentTypeDropdown,
    };

    const targetPaymentToolDropdownProps = {
      targetDropdownValue: targetPaymentTypeDropdown,
      targetDropdownSaveCallback: dropdownSaveCallbacks[copyCommissionType].targetPaymentTypeDropdownSave,
      isTargetDropdownSingle:
        DROPDOWNS_MULTI_CHOICE_AVAILABILITY.TARGET_DROPDOWNS[copyCommissionType].paymentTypeDropdown,
      isTargetDropdownDisable: DISABLE_AVAILABILITY[copyCommissionType].paymentTypeDropdown,
      targetDropdownErrorMessage: formErrors.targetPaymentTypeDropdown,
    };

    return (
      <div className="CommissionDetails-selects">
        <Row
          className={`CommissionDetails-selectsRow CommissionDetails-${DROPDOWNS_ORDERS[copyCommissionType].profileDropdown}OrderRow`}
        >
          {this.renderSelectsRow(
            translate('page.commissionsServices.profiles'),
            profiles,
            PROFILES_COLUMN_CONFIG,
            translate('page.commissionsServices.chooseProfile'),
            sourceProfileDropdownProps,
            targetProfileDropdownProps,
          )}
        </Row>

        <Row
          className={`CommissionDetails-selectsRow CommissionDetails-${DROPDOWNS_ORDERS[copyCommissionType].serviceDropdown}OrderRow`}
        >
          {this.renderSelectsRow(
            translate('page.commissionsServices.services'),
            services,
            SERVICES_COLUMN_CONFIG,
            translate('page.commissionsServices.chooseService'),
            sourceServiceDropdownProps,
            targetServiceDropdownProps,
          )}
        </Row>

        <Row
          className={`CommissionDetails-selectsRow CommissionDetails-${DROPDOWNS_ORDERS[copyCommissionType].paymentTypeDropdown}OrderRow`}
        >
          {this.renderSelectsRow(
            translate('page.commissionsServices.paymentTypes'),
            paymentTypes,
            PAYMENT_TYPES_COLUMN_CONFIG,
            translate('page.commissionsServices.choosePaymentType'),
            sourcePaymentToolDropdownProps,
            targetPaymentToolDropdownProps,
          )}
        </Row>
      </div>
    );
  };

  renderActions = () => {
    const { translate, onCopy, formErrors, getIsErrorsExist } = this.props;

    return (
      <Row>
        <Button type="primary" onClick={onCopy} disabled={!!getIsErrorsExist(formErrors)}>
          {translate('core.buttonTitles.copy')}
        </Button>

        <Button
          type="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          {translate('core.buttonTitles.refuse')}
        </Button>
      </Row>
    );
  };

  render() {
    const {
      isLoading,
      isDialogVisible,
      toggleVisibleDialog,
      translate,
      formState: { isOldCommissionDelete },
      onChangeFormState,
    } = this.props;

    return (
      <div className="CommissionDetails">
        {this.renderRadioGroup()}
        {this.renderSelects()}
        <Row className="OldCommissionDeleteRow">
          <Col span={2} md={1}>
            <Checkbox
              checked={isOldCommissionDelete}
              onChange={(e) => {
                onChangeFormState('isOldCommissionDelete', !isOldCommissionDelete);
              }}
            />
          </Col>
          <Col span={22} md={16}>
            {translate('page.commissionsServices.deleteOldCommissions')}
          </Col>
        </Row>
        {this.renderActions()}
        {isDialogVisible && (
          <InfoModal
            closeModal={() => {
              toggleVisibleDialog(false);
              window.location.reload();
            }}
            visible={isDialogVisible}
            modalTitle={translate('page.commissionsServices.copyingCommissions')}
            content={translate('page.commissionsServices.warningMessage')}
          />
        )}
        {isLoading && <LoadingPanel />}
      </div>
    );
  }
}

export default withTranslate(CopyCommission);
