import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { Col, Row, Input, message } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import { withTranslate } from '../../contexts/localContext';
import { ApiService, tabService } from '../../services';
import DateRange from '../../components/daterange';
import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import RecalculationModal from './RecalculationModal';
import ModalEncashmentCheck from './ModalEncesmentCheck/ModalEncesmentCheck';

const api = new ApiService();

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;

const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
const aggregate = [
  { field: 'CollectionId', aggregate: 'count', format: 'n0' },
  { field: 'Amount', aggregate: 'sum', format: 'n2' },
  { field: 'AmountActually', aggregate: 'sum', format: 'n2' },
  { field: 'AmountBills', aggregate: 'sum', format: 'n2' },
];

class GridEncashmentHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('encashment');
  }

  state = {
    isLoading: false,
    data: [],
    range: {
      start: null,
      end: null,
    },
    currentSelectTerminalInfo: null,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  componentDidMount() {
    this.makeQuery();
  }

  makeQuery = async () => {
    const { range } = this.state;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);
    const { ownerId, terminalId } = this.props;
    if (!ownerId || !terminalId) return;
    try {
      const model = {
        OwnerId: [ownerId],
        TerminalId: terminalId,
        DateStart: start,
        DateEnd: end,
        Id: 'string',
        Extended: true,
      };
      this.toggleLoader(true);
      const data = await api.encashmentHistory(model);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range });
  };

  buildToolbar = () => {
    const { translate, terminalId } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <DateRange onRange={this.setRange} />
          </Col>
          {checkElement('encashmentHistory-btn-submit', this.currentArray) && (
            <Col className="md-mt-10" md={24} lg={4}>
              <Row className="md-daterange--row" type="flex" justify="center">
                <Button
                  className="sm-w-100"
                  icon="search"
                  style={{ marginTop: '10px' }}
                  primary
                  onClick={this.makeQuery}
                >
                  {translate('owner.search')}
                </Button>
              </Row>
            </Col>
          )}
        </Row>
        <div className="issued-certificates--toolbar aligned">
          <p>{translate('grids.certificates-issued.col-terminalId')}</p>
          <Input value={terminalId} disabled />
        </div>
      </Col>
    );
  };

  handleRecalculation = async () => {
    const { currentSelectTerminalInfo } = this.state;
    const { translate } = this.props;

    try {
      this.setState({ currentSelectTerminalInfo: null });
      await this.toggleLoader(true);
      await api.recountCollection({
        CollectionId: currentSelectTerminalInfo.CollectionId,
      });
      message.success(`${translate('grids.encashment.text-single-collection-recalc-popup-success')}`, 2.5);
      await this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.toggleLoader(false);
    }
  };

  handleDownload = async (collectionId) => {
    try {
      await this.toggleLoader(true);
      const response = await api.getCollectionReceiptPDF(collectionId);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${response}`;
      downloadLink.download = `CollectionCheck_${collectionId}.pdf`;
      downloadLink.click();
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      this.toggleLoader(false);
    }
  };

  handleSentCheck = (collectionId) => {
    this.setState({ visible: true, collectionId });
  };

  getHandlers = () => {
    return [
      {
        title: 'page.encashment.tab-singleEncashmentRecalc',
        action: ({ dataItem }) => {
          this.setState({ currentSelectTerminalInfo: dataItem });
        },
        dropdown: false,
        show: checkElement('encashment-singleCollectionRecalculation', this.currentArray),
      },
      {
        title: 'grids.encashment.collectionCheck',
        action: ({ dataItem }) => {
          this.handleDownload(dataItem.CollectionId);
        },
        dropdown: false,
        show: checkElement('encashment-printCheck', this.currentArray),
      },
      {
        title: 'page.encashment.tab-encashment-check-email',
        action: ({ dataItem }) => this.handleSentCheck(dataItem.CollectionId),
        dropdown: false,
        show: checkElement('encashment-printCheckEmail', this.currentArray),
      },
    ];
  };

  onCloseModal = () => {
    this.setState({ visible: false });
  };
  render = () => {
    const { isLoading, data, currentSelectTerminalInfo, visible, collectionId } = this.state;
    const { translate } = this.props;

    const data2 = [
      {
        collectionId: 0,
        datePostNoUTC: '2024-02-22T10:20:50.101Z',
        terminalId: 0,
        terminalName: 'string',
        userName: 'string',
        collectionIdCount: 0,
        amount: 0,
        amountActually: 0,
        amountBills: 0,
        currency: 'string',
        active: true,
        city_name: 'string',
        regionName: 'string',
        address: 'string',
        amountDifference: 0,
        userNameClose: 'string',
        userId: 0,
        ownerId: 0,
        ownerName: 'string',
        userNameExec: 'string',
        countCash: 0,
        branch: 'string',
        note: 'string',
        qrClose: true,
        datePost: '2024-02-22T10:20:50.101Z',
      },
    ];

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="encashmentHistory"
          permissionName="encashment"
        >
          <GridColumn field="OwnerName" title="grids.allEncashments.column-ownername" width="150" />

          <GridColumn field="TerminalId" title="grids.allEncashments.column-terminalId" width="134" filter="numeric" />

          <GridColumn
            field="CollectionId"
            title="grids.allEncashments.column-collectionId"
            width="150"
            filter="numeric"
            dropdown={checkElement('encashmentHistory-isShowDropdown', this.currentArray)}
            handlers={this.getHandlers()}
          />

          <GridColumn field="RegionName" title="grids.allEncashments.column-regionName" width="150" />

          <GridColumn field="City_name" title="grids.allEncashments.column-cityName" width="150" />

          <GridColumn field="Address" title="grids.allEncashments.column-address" width="150" />

          <GridColumn field="TerminalName" title="grids.allEncashments.column-terminalName" width="150" />

          <GridColumn field="UserName" title="grids.allEncashments.column-userName" width="150" />

          <GridColumn
            field="DatePost"
            title="grids.allEncashments.column-datePostNoUTC"
            width="240"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />

          <GridColumn
            field="Amount"
            title="grids.allEncashments.column-amount"
            width="150"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountActually"
            title="grids.allEncashments.column-amountActually"
            width="150"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountBills"
            title="grids.allEncashments.column-amountBills"
            width="150"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn
            field="AmountDifference"
            title="grids.allEncashments.column-amountDifference"
            width="145"
            filter="numeric"
            format="{0:n2}"
          />

          <GridColumn field="Currency" title="grids.allEncashments.column-currency" width="100" />

          <GridColumn
            field="Active"
            title="grids.allEncashments.column-active"
            width="100"
            cell={WrappedCellBoolean}
            filter="boolean"
          />

          <GridColumn field="UserNameClose" title="grids.allEncashments.column-userNameClose" width="150" />

          <GridColumn field="UserId" title="grids.allEncashments.column-userId" width="150" filter="numeric" />

          <GridColumn field="UserNameExec" title="grids.allEncashments.column-userNameExec" width="150" />

          <GridColumn field="CountCash" title="grids.allEncashments.column-countCash" width="150" filter="numeric" />
        </Grid>
        {currentSelectTerminalInfo && (
          <RecalculationModal
            visible={currentSelectTerminalInfo}
            closeModal={() => {
              this.setState({ currentSelectTerminalInfo: null });
            }}
            submitCallback={this.handleRecalculation}
            deleteContent={`${translate('grids.encashment.text-single-collection-recalc-popup-title')} ${
              currentSelectTerminalInfo.CollectionId
            }?`}
          />
        )}
        {visible && <ModalEncashmentCheck visible={visible} collectionId={collectionId} onClose={this.onCloseModal} />}
      </>
    );
  };
}

export default withTranslate(GridEncashmentHistory);
