export const availableTablesList = [
  'adminPanel',
  'adminStockPhotos',
  'adminGeneralResults',
  'subAdminStockPhotos',
  'ownerStockGeneralResults',
  'ownerStockPhotos',
  'terminalOperabilityPeriodChoose',
  'terminalOperabilityDayChoose',
  'whiteListAdminDelete',
  'whiteListAdminAdd',
  'screenControlAssignBusinessServices',
  'screenControlTerminalEvents',
  'addRemoteSettings',
  'encashmentSendingCheck',
  'addingAddressedPaymentApplications',
  'reportUsersInStopListGet',
  'reportRegularPaymentsGet',
  'reportPayerRecipientDataGet',
  'reportNoRecipientDataGet',
  'reportMFOPaymentsGet',
  'reportRefundPaymentsGet',
  'reportWithdrawGet',
  'reportOverlimitPaymentsGet',
  'reportTerroristsTransactionsGet',
  'dictionaryAgentsGet',
  'dictionaryRefusedAgentsGet',
  'reportOverlimitTransactionsGet',
  'commissionActionResults-grid',
  'gridAllEncashmentsWithGraphQl',
  'encashmentDetailsListTest',
  'allEncashmentsWidthAllTechnologies',
  'allElementsGrid',
  'terminalsByService-grid',
  'reportTerminalsByServices-grid',
  'residuesInTerminals-grid',
  'cashCollectors-grid',
  'сommissionManagementProfiles-grid',
  'terminalsByDays-grid',
  'reportTerminalsByDays-grid',
  'terminalsByDays-grid',
  'reportTerminalsByDays-grid',
  'reportTerminalsByDays-grid',
  'dispenserTerminalsGrid',
  'CardsAvailabilityGrid',
  'collectionHistoryGrid',
  'userList-grid',
  'releasesGrid',
  'userRolesGrid',
  'workWithDealers-grid',
  'terminalsAccess-grid',
  'backupButtonsGrid',
  'templateAssignmentsGrid',
  'NonRiskLimitsGrid',
  'NonRiskServicesGrid',
  'fishkaServiceGrid',
  'fishkaHistoryServiceGrid',
  'CashDebtByCollectorsGrid',
  'suspendedFinancialTransactionsGrid',
  'suspendedFinancialTransactionsActionsGrid',
  'exceptionsFinMonLimitGrid',
  'servicesBigTableGrid',
  'DealerCommissionsGrid',
  'DealerCommissionsPaymentToolsGrid',
  'ChangeDealerCommissionGrid',
  'addNewServicesGrid',
  'unlocksSuspendedOperationsHistoryGrid',
  'dealerCommissions-grid',
  'services-grid',
  'dictionaryCities-grid',
  'paymentTypeNBUCodeGrid',
  'recordDeletionHistoryGrid',
  'additionalLimitsGrid',
  'serviceLimitsGrid',
  'terminalsEncashment',
  'subBrandsTerminalsGrid',
  'subBrandsDictionaryGrid',
  'financialTransactions',
  'searchSanctionTerroristGrid',
  'financialTransactionDetailsGrid',
  'servicesAdditionalPropsGrid',
  'serviceEndpointsGrid',
  'suppliersBalanceGrid',
  'RecalculationTransactionsGrid',
  'servicesAuthorizationLimitsGrid',
  'dealerCommissionsTerminalsGrid',
  'managementPaymentTypesGrid',
  'dealerCommissionsHistoryGrid',
  'terminalMaintenance-grid',
  'authorizationsDictionaryGrid',
  'OTPServicesGrid',
  'OTPServicesHistoryGrid',
  'templateServicesGrid',
  'templateTerminalsGrid',
  'dealerCommissionsExpectedChangesGrid',
  'paymentTypesDictionaryGrid',
  'GroupServicesGrid',
  'cardRoutingGrid',
  'updateCardRoutingGrid',
  'supplierCommissionsScaleGrid',
  'supplierCommissionsScaleFeatureGrid',
  'commissionsTechnicalPartnerGrid',
  'MenuProfilesGrid',
  'MenuElementsGrid',
  'terminalProfilesGrid',
  'MenuAuditGrid',
  'MenuElementPropDictionaryGrid',
  'navigationMenuPropGrid',
  'EasyPayBalanceGrid',
  'managementErrorCodesGrid',
  'PartnerInfoSenderGrid',
  'SUOGrid',
  'PreviewStatusApplicationGrid',
  'manageMerchantCallbacksGrid',
  'reportByPaymentTypesGrid',
  'MFOList',
  'availableToUsersGrid',
  'TechnicalPartnersGrid',
  'ServiceSuppliersGrid',
  'ServiceSuppliersTreatiesGrid',
  'ServiceSuppliersAccountsGrid',
  'ServiceRelatedSuppliersGrid',
  'NavigationMenuElementsGrid',
  'beneficiariesGrid',
  'directorsGrid',
  'FiscalizedTransactionsDaysReportGrid',
  'FiscalizedTransactionsOwnersDaysReportGrid',
  'terminalTransactions',
  'monitorTerminalTransactions',
  'terminalTransactions',
  'gridTransactionWithAdditionalPropsGrid',
  'monitorActivityGridTransactionWithAdditionalPropsGrid',
  'managementRoutingBankListGrid',
  'screenControlTerminalsConfig-grid',
  'serviceCreationClientCommissionsGrid',
  'additionalTransactionInfo',
  'transactionsWithCashback',
];

export const OPERATOR_BY_GQL_MAP = new Map([
  ['doesnotcontain', 'ncontains'],
  ['startswith', 'startsWith'],
  ['endswith', 'endsWith'],
]);
