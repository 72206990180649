import React, { PureComponent } from 'react';
import { message } from 'antd';

import FormStateContainer from '../../../../../components/FormStateContainer';
import SupplierCommissions from './SupplierCommissions';
import { INITIAL_VALUES } from './constants';
import { ApiService } from '../../../../../services';
import LoadingPanel from '../../../../../components/loader';
import { withTranslate } from '../../../../../contexts/localContext';
import { getDataByTimezoneOffset } from '../../../../../utils/getDataByTimezoneOffset';
import { getCustomDate } from '../../../../../utils/getCustomDate';
import SupplierCommissionsService from '../../SimpleSupplierСommissionsForm/SupplierСommissions.service';

const api = new ApiService();

class SupplierCommissionsContainer extends PureComponent {
  state = {
    isLoading: false,
  };

  saveSupplierCommissions = async ({
    commissionPercent,
    commissionMin,
    commissionMax,
    commissionCalculationBase,
    commissionCardPercent,
    commissionCardMin,
    commissionCardMax,
    commissionCardCalculationBase,
  }) => {
    const { handleUpdateTabPane, currentServiceId, translate } = this.props;
    try {
      await this.setState({ isLoading: true });
      await api.serviceSupplierCommissionInsert({
        serviceId: currentServiceId,
        dateStart: getDataByTimezoneOffset(
          getCustomDate({
            customDayOfMonth: new Date().getDate() + 1,
          }),
        ).toISOString(),
        supplierProviderAmountTotal: commissionCalculationBase,
        supplierProviderPercent: SupplierCommissionsService.getParsedValue(commissionPercent),
        supplierProviderMin: SupplierCommissionsService.getParsedValue(commissionMin),
        supplierProviderMax: SupplierCommissionsService.getParsedValue(commissionMax),
        supplierCardProviderAmountTotal: commissionCardCalculationBase,
        supplierCardProviderPercent: SupplierCommissionsService.getParsedValue(commissionCardPercent),
        supplierCardProviderMin: SupplierCommissionsService.getParsedValue(commissionCardMin),
        supplierCardProviderMax: SupplierCommissionsService.getParsedValue(commissionCardMax),
      });
      await message.success(`${translate('page.servicesBigTable.supplierCommissionsSuccessfullySaved')}`, 2);
      this.setState({
        isLoading: false,
      });

      handleUpdateTabPane();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    const { currentServiceId } = this.props;

    return (
      <>
        <FormStateContainer initialValues={INITIAL_VALUES}>
          <SupplierCommissions
            saveSupplierCommissions={this.saveSupplierCommissions}
            currentServiceId={currentServiceId}
          />
        </FormStateContainer>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(SupplierCommissionsContainer);
