import React, { PureComponent } from 'react';
import { Button, message, Modal, Input } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { withTranslate } from '../../../contexts/localContext';
import LoadingPanel from '../../../components/loader';
import { ApiService } from '../../../services';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

import '../SupplierProps/SupplierProps.styled.scss';

const api = new ApiService();

class TreatiesUpdateModal extends PureComponent {
  state = {
    isLoading: false,
    treatiesNumber: '',
    treatiesPostDate: new Date(),
    treatiesTerminalDate: null,
  };

  componentDidMount() {
    const { selectedItem } = this.props;

    if (selectedItem) {
      this.setState({
        treatiesNumber: selectedItem.TreatyNumber,
        treatiesPostDate: selectedItem.TreatyDate ? new Date(selectedItem.TreatyDate) : null,
        treatiesTerminalDate: selectedItem.TerminationDate ? new Date(selectedItem.TerminationDate) : null,
      });
    }
  }

  renderInputRow = ({ label, inputComponent }) => {
    const { translate } = this.props;

    return (
      <div className="SupplierProps-inputRow">
        <div className="SupplierProps-label">{translate(label)}</div>
        <div className="SupplierProps-input">{inputComponent}</div>
      </div>
    );
  };

  updateTreaties = async () => {
    const { variant, supplierId, translate, closeModal, onRefresh, selectedItem } = this.props;
    const { treatiesPostDate, treatiesNumber, treatiesTerminalDate } = this.state;

    try {
      await this.setState({ isLoading: true });
      const method = variant === 'add' ? api.suppliersTreatiesAdd : api.suppliersTreatiesUpdate;

      const model =
        variant === 'add'
          ? {
              supplierId,
              treatyId: 0,
              treatyDate: getDataByTimezoneOffset(treatiesPostDate).toISOString(),
              treatyNumber: treatiesNumber,
            }
          : {
              treatyId: selectedItem.TreatyId,
              treatyDate: getDataByTimezoneOffset(treatiesPostDate).toISOString(),
              treatyNumber: treatiesNumber,
              terminationDate: treatiesTerminalDate
                ? getDataByTimezoneOffset(treatiesTerminalDate).toISOString()
                : null,
            };

      const successfulMessage = `page.serviceSupplier.${
        variant === 'add' ? 'treatiesSuccessfulAdded' : 'treatiesSuccessfulEdited'
      }`;

      await method(model);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate(successfulMessage), 2);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { variant, closeModal, translate } = this.props;
    const { isLoading, treatiesPostDate, treatiesNumber, treatiesTerminalDate } = this.state;

    return (
      <Modal
        visible={variant}
        onCancel={closeModal}
        title={translate(`page.serviceSupplier.${variant === 'add' ? 'addTreaties' : 'editTreaties'}`)}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={this.updateTreaties}
            disabled={!treatiesNumber || !treatiesPostDate}
          >
            {translate(`core.buttonTitles.${variant === 'add' ? 'add' : 'edit'}`)}
          </Button>,

          <Button key="submit" type="primary" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {this.renderInputRow({
          label: 'page.serviceSupplier.col-treatiesId',
          inputComponent: (
            <Input
              value={treatiesNumber}
              onChange={({ target: { value: treatiesNumber } }) => {
                this.setState({ treatiesNumber });
              }}
            />
          ),
        })}

        {this.renderInputRow({
          label: 'page.serviceSupplier.col-dateTreaties',
          inputComponent: (
            <DatePicker
              value={treatiesPostDate}
              format="dd.MM.yyyy"
              onChange={({ target: { value: treatiesPostDate } }) => {
                this.setState({ treatiesPostDate });
              }}
            />
          ),
        })}

        {treatiesTerminalDate &&
          this.renderInputRow({
            label: 'page.serviceSupplier.col-terminationDate',
            inputComponent: (
              <DatePicker
                value={treatiesTerminalDate}
                format="dd.MM.yyyy"
                onChange={({ target: { value: treatiesTerminalDate } }) => {
                  this.setState({ treatiesTerminalDate });
                }}
                disabled
              />
            ),
          })}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(TreatiesUpdateModal);
