import { uppercaseFirstLetter } from '../../utils/uppercaseFirstLetter';
import { NORMALIZE_RESPONSE_FIELD_MAP } from './constants';
import { getDateDifferenceString } from '../../utils/getDateDifferenceString';

class MonitorActivityService {
  normalizeTerminalsList(terminalListActivity, translate) {
    return terminalListActivity.length
      ? terminalListActivity.map((item) =>
          Object.entries({
            ...item,
            lastScreenTouch: getDateDifferenceString(new Date(), item.lastScreenTouch, translate),
          }).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [NORMALIZE_RESPONSE_FIELD_MAP.get(key) || uppercaseFirstLetter(key)]: value,
            }),
            {},
          ),
        )
      : [];
  }

  getGQLResponse = (data, translate) => {
    if (!data || !data.data || !data.data.terminalActivityMonitorList) {
      return { terminalListActivity: [], hasNextPage: false, hasPreviousPage: false };
    }
    const terminalListActivity = data.data.terminalActivityMonitorList;

    const resultTerminalListActivity =
      terminalListActivity.items && terminalListActivity.items.length
        ? this.normalizeTerminalsList(terminalListActivity.items, translate)
        : [];

    const pageInfo = terminalListActivity.pageInfo;

    console.log('resultTerminalListActivity', resultTerminalListActivity);

    return {
      terminalListActivity: resultTerminalListActivity,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
      hasPreviousPage: pageInfo && pageInfo.hasPreviousPage,
    };
  };

  getAllItemsGQLResponse = (data, translate) => {
    if (
      !data ||
      !data.data ||
      !data.data.terminalActivityMonitorListAll ||
      !data.data.terminalActivityMonitorListAll.length
    ) {
      return [];
    }

    return this.normalizeTerminalsList(data.data.terminalActivityMonitorListAll, translate);
  };
}
export default new MonitorActivityService();
