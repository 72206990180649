const allEncashments = (translate, value) => {
  switch (value) {
    case 'allEncashments-selected':
      return `${translate(`page.adminPanel.type-column-selected`)}`;
    // case 'allEncashments-сollectionIdOnclick':
    //   return `${translate(`page.adminPanel.type-navLink`)}:
    //           ${translate('page.encashment.tab-encashmentDetailsList')}`
    case 'allEncashments-Active':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-active')}`;
    case 'allEncashments-Address':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-address')}`;
    case 'allEncashments-Amount':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-amount')}`;
    case 'allEncashments-AmountActually':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-amountActually')}`;
    case 'allEncashments-AmountBills':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-amountBills')}`;
    case 'allEncashments-AmountDifference':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-amountDifference')}`;
    case 'allEncashments-Note':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('page.terminalSettings.field-notes')}`;
    case 'allEncashments-Branch':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-branch')}`;
    case 'allEncashments-btn-submit':
      return `${translate(`page.adminPanel.type-button`)}:
              ${translate('owner.search')}`;
    case 'allEncashments-btn-cash':
      return `${translate(`page.adminPanel.type-navLink`)}:
              ${translate('grids.allEncashments.btn-cash')}`;
    case 'allEncashments-City_name':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-cityName')}`;
    case 'allEncashments-CollectionId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-collectionId')}`;
    case 'allEncashments-CountCash':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-countCash')}`;
    case 'allEncashments-Currency':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-currency')}`;
    case 'allEncashments-DatePost':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-datePostNoUTC')}`;
    case 'allEncashments-OwnerName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-ownername')}`;
    case 'allEncashments-RegionName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-regionName')}`;
    case 'allEncashments-TerminalId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-terminalId')}`;
    case 'allEncashments-TerminalName':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-terminalName')}`;
    case 'allEncashments-UserId':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-userId')}`;
    case 'allEncashments-UserName':
      return `${translate(`page.adminPanel.type-column`)}:n
              ${translate('grids.allEncashments.column-userName')}`;
    case 'allEncashments-UserNameClose':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-userNameClose')}`;
    case 'allEncashments-UserNameExec':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-userNameExec')}`;
    case 'allEncashments-QrClose':
      return `${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.allEncashments.column-QRStatusWithTranslate')}`;
    case 'encashmentDetailsList-Currency':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-currency')}`;
    case 'encashmentDetailsList-Nominal':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-nominal')}`;
    case 'encashmentDetailsList-Quantity':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-quantity')}`;
    case 'encashmentDetailsList-QuantityActually':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-quantityActually')}`;
    case 'encashmentDetailsList-QuantityBills':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-quantitybills')}`;
    case 'encashmentDetailsList-IsCash':
      return `${translate('page.adminPanel.type-tab')}:
              ${translate('page.encashment.tab-encashmentDetailsList')},
              ${translate(`page.adminPanel.type-column`)}:
              ${translate('grids.encashmentDetailsList.column-isCash')}`;
    default:
      return value;
  }
};

export default allEncashments;
