import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../baseGrid';
import checkElement from '../../../utils/checkElement';
import { tabService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import AdditionalTransactionsPropsService from '../../AdditionalTransactions/AdditionalTransactionsProps.service';
import { withTranslate } from '../../../contexts/localContext';

class GridTransactionWithAdditionalProps extends PureComponent {
  currentArray = findArray('searchtransactions');
  state = {
    transactions: [],
    isLoading: false,
  };

  componentDidMount() {
    this.initial();
  }

  renderTransactionIdOnclick = (props) => {
    if (checkElement('terminalTransactions-transactionIdOnclick', this.currentArray))
      return tabService.addTab({ type: 'details', dataItem: props.dataItem });
    else return null;
  };

  initial = async () => {
    const {
      dataItem: { selectedItems, selectedAdditionalProps },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const allProps = await AdditionalTransactionsPropsService.fetchTransactionsProps(
        0,
        [],
        'transactionId, itemName, itemValue',
        `{and:[{transactionId:{in:[${selectedItems
          .map(({ transactionId }) => transactionId)
          .join(',')}]}}, {itemName:{in:[${selectedAdditionalProps
          .map((fieldName) => `"${fieldName}"`)
          .join(',')}]}}]}`,
      );

      await this.setState({
        isLoading: false,
        transactions:
          allProps && allProps.length
            ? AdditionalTransactionsPropsService.getTransactionListWithAdditionalProps(allProps, selectedItems)
            : selectedItems,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { transactions, isLoading } = this.state;
    const {
      dataItem: { selectedAdditionalProps },
    } = this.props;

    return (
      <Grid
        data={transactions}
        onRefresh={this.initial}
        toolbar={<></>}
        isLoading={isLoading}
        name="gridTransactionWithAdditionalPropsGrid"
        withoutManageColumnAction
      >
        {[
          <GridColumn field="awnerName" width="145" title="grids.transactions.column-ownerName" />,
          <GridColumn
            field="transactionId"
            title="grids.transactions.column-transactionId"
            width="130"
            onClick={(props) => this.renderTransactionIdOnclick(props)}
            filter="numeric"
          />,
          <GridColumn field="requestId" title="grids.transactions.column-requestId" width="112" filter="numeric" />,
          <GridColumn field="terminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />,
          <GridColumn field="serviceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />,
          <GridColumn field="serviceName" title="grids.transactions.column-serviceName" width="93" />,
          <GridColumn
            field="amountTotal"
            title="grids.transactions.column-amountTotal"
            width="134"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="amountOriginal"
            title="grids.transactions.column-amountOriginal"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="commissionProvider"
            title="grids.transactions.column-comissionProvider"
            width="154"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn
            field="commissionClient"
            title="grids.transactions.column-comissionClient"
            width="171"
            filter="numeric"
            format="{0:n2}"
          />,
          <GridColumn field="currency" title="grids.transactions.column-currency" width="90" />,
          <GridColumn
            field="dateInput"
            title="grids.transactions.column-dateInput"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="datePost"
            title="grids.transactions.column-datePost"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="dateDeclined"
            title="grids.transactions.column-dateDeclined"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn
            field="dateAccepted"
            title="grids.transactions.column-dateAccepted"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />,
          <GridColumn field="account" title="grids.transactions.column-account" width="112" />,
          <GridColumn field="comment" title="grids.transactions.column-comment" width="125" />,
          <GridColumn field="statusName" title="grids.transactions.column-status" width="91" />,
          <GridColumn field="paymentType" title="grids.transactions.column-typePayment" width="125" />,
          ...selectedAdditionalProps.map((filedName) => (
            <GridColumn
              field={filedName}
              title={filedName}
              width="180"
              //кастомна функція для cell яка по суті повторує нативну була додана через те, шо нативна функція не сприймає field з точкою,
              // наприклад 'balance.after', осклільки в ній(в нативній) ще закладена логіка для підколонок, які як раз і використовують "." під час парсингу
              cell={({ field, dataItem }) => <td>{dataItem[field]}</td>}
            />
          )),
        ]}
      </Grid>
    );
  }
}

export default withTranslate(GridTransactionWithAdditionalProps);
