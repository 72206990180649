export const TRANSLATE_SLUG_BY_KEY = new Map([
  ['accountmanager', ['page-nav.nav-manageAccounts']],
  ['activitymonitor', ['page-nav.nav-activityMonitor']],
  ['allEncashments', ['page.encashment.tab-allEncashments']],
  ['certificates', ['page-nav.nav-certificates']],
  ['commissionClient', ['page-nav.nav-commissions']],
  ['contacts', ['page-nav.nav-contacts']],
  ['createTerminal', ['page-nav.nav-new-terminals']],
  ['dashboard', ['page-nav.nav-main']],
  ['encashment', ['page-nav.nav-collection']],
  ['reportbyday', ['page-nav.nav-report', 'page-nav.nav-reportDay']],
  ['reportonservice', ['page-nav.nav-report', 'page-nav.nav-reportServices']],
  ['reportPeriod', ['page-nav.nav-report', 'page-nav.nav-reportPeriod']],
  ['reportTurnByTerminals', ['page-nav.nav-report', 'page-nav.nav-reportTurn']],
  ['terminalMaintenance', ['page-nav.nav-report', 'page-nav.nav-terminalMaintenance']],
  ['screenControlBusinessServices', ['page-nav.nav-terminal-screen-management-business-services']],
  ['screenControlProfiles', ['page-nav.nav-terminal-screen-management-profiles']],
  ['screenControlTerminals', ['page-nav.nav-terminal-screen-management-terminals']],
  ['searchtransactions', ['page-nav.nav-viewTransactions']],
  ['tech-inform', ['page-nav.nav-techInform']],
  ['terminalOperabilityPeriod', ['page-nav.nav-report', 'page-nav.nav-operabilityPeriod']],
  ['terminalOperabilityDay', ['page-nav.nav-report', 'page-nav.nav-operabilityDay']],
  ['whiteListAdmin', ['page-naManagementCommissionTerminalsv.nav-dictionary', 'page-nav.nav-whiteList']],
  ['messages', ['page-nav.nav-messages']],
  ['adding-addressed-payment', ['page-nav.nav-adding-addressed-payment']],
  ['screenControlSettings', ['page-nav.nav-terminal-control-settings']],
  ['screenControlTerminalsConfig', ['page-nav.nav-terminal-screen-management', 'page-nav.nav-config-equipment']],
  ['handlingCommissions', ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-commission']],
  ['сommissionManagementProfiles', ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-profiles']],
  ['dictionaryCities', ['page-nav.nav-dictionary', 'page-nav.nav-cities']],
  ['CashDept', ['page-nav.nav-report', 'page-nav.nav-cashdebt']],
  [
    'changingManagementCommissionProfileHistory',
    ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-profileChangeHistory'],
  ],
  [
    'ManagementCommissionTerminals',
    ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-commissionsTerminals'],
  ],
  ['monitoringCommissionServices', ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-commissionsServices']],
  [
    'changingCommissionProfileHistory',
    ['page-nav.nav-managementExternalCommissions', 'page-nav.nav-profileChangeHistory'],
  ],
  ['dealerCommissions', ['page-nav.nav-dictionary', 'page-nav.nav-dealer-commissions']],
  ['dictionaryServices', ['page-nav.nav-dictionary', 'page-nav.nav-dictionary-services']],
  ['collectorsCashReport', ['page-nav.nav-encashment', 'page-nav.nav-cashcollectors']],
  ['remainingTerminals', ['page-nav.nav-encashment', 'page.dashboard.tab-remainingTerminals']],
  ['terminalsByDays', ['page-nav.nav-report', 'page-nav.nav-terminalsByDays']],
  ['terminalsByServices', ['page-nav.nav-report', 'page-nav.nav-terminalsByServices']],
  ['dispenserCollection', ['page-nav.nav-encashment', 'page.dispenserCollection.mainTab']],
  ['releasePage', ['page-nav.tab-adminPanel', 'page-nav.nav-ElementsOfAllReleases']],
  ['users', ['page-nav.nav-users']],
  ['backupButtons', ['page-nav.nav-terminal-screen-management', 'page-nav.nav-backupButtons']],
  ['nonRiskLimits', ['page-nav.nav-nonRiskLimits']],
  ['fishkaService', ['page.fishkaService.navTab-сyrillicName']],
  ['cashDebtByCollectors', ['page-nav.nav-report', 'page-nav.nav-cashDebtByCollectors']],
  ['serviceManagement', ['page-nav.nav-serviceManagement', 'page-nav.nav-services']],
  ['dealerCommissionsServices', ['page-nav.nav-commissionsDealer', 'page-nav.nav-commissionsDealer-services']],
  ['dealerCommissionsPaymentTools', ['page-nav.nav-commissionsDealer', 'page-nav.nav-commissionsDealer-paymentTools']],
  ['dealerCommissionsTerminals', ['page-nav.nav-commissionsDealer', 'page-nav.nav-dealerCommissionsTerminals']],
  ['dealerCommissionsHistory', ['page-nav.nav-commissionsDealer', 'page-nav.nav-dealerCommissionsHistory']],
  [
    'dealerCommissionsExpectedChanges',
    ['page-nav.nav-commissionsDealer', 'page-nav.nav-dealerCommissionsExpectedChanges'],
  ],
  ['exceptionsFinMonLimit', ['page-nav.nav-serviceManagement', 'page-nav.nav-exceptionsFinMonLimit']],
  ['serviceGroupsDictionary', ['page-nav.nav-serviceManagement', 'page-nav.nav-groupServices']],
  ['SuspendedFinancialTransactions', ['page-nav.nav-suspendedFinancialTransactions']],
  ['NBUServicesRelations', ['page-nav.nav-serviceManagement', 'page-nav.nav-paymentTypeNBUCode']],
  ['serviceLimits', ['page-nav.nav-dictionary', 'page-nav.nav-serviceLimits']],
  ['authorizationsDictionary', ['page-nav.nav-dictionaryManagement', 'page-nav.nav-authorizationsDirectory']],
  ['dictionaryPaymentTypes', ['page-nav.nav-dictionaryManagement', 'page-nav.nav-paymentTypesDictionary']],
  ['terminalsSubBrands', ['page-nav.nav-terminal-screen-management', 'page-nav.nav-subBrandsTerminals']],
  ['blockedTransactions', ['page-nav.nav-blockedTransactions']],
  ['financialTransactions', ['page-nav.nav-viewFinancialTransactions']],
  ['monitoringCabinet', ['page-nav.nav-monitoringCabinet']],
  ['admin', ['page-nav.tab-adminPanel', 'page-nav.nav-admin']],
  ['suppliersBalance', ['page-nav.nav-suppliersBalance']],
  ['authorizationServicesLimitsGrid', ['page-nav.nav-servicesAuthorizationLimits']],
  ['recalculationTransactions', ['page-nav.nav-serviceManagement', 'page-nav.nav-recalculationTransactions']],
  ['OTPServices', ['page-nav.nav-serviceManagement', 'page-nav.nav-OTRServices']],
  ['manageTemplate', ['page-nav.nav-checkTemplate', 'page-nav.nav-checkTemplate']],
  ['servicesTemplate', ['page-nav.nav-checkTemplate', 'page-nav.nav-templateServices']],
  ['templateTerminals', ['page-nav.nav-checkTemplate', 'page-nav.nav-templateTerminals']],
  ['paymentTypesManagement', ['page-nav.nav-serviceManagement', 'page-nav.nav-managementPaymentTypes']],
  ['cardRouting', ['page-nav.nav-routingManagement']],
  ['managementMenuProfiles', ['page-nav.nav-managementMenu', 'page-nav.nav-menuProfiles']],
  ['menuAudit', ['page-nav.nav-managementMenu', 'page-nav.nav-menuAudit']],
  ['terminalProfiles', ['page-nav.nav-managementMenu', 'page-nav.nav-terminalProfiles']],
  ['menuElementPropDictionary', ['page-nav.nav-managementMenu', 'page-nav.nav-elementPropertyDirectory']],
  ['navigationMenuProp', ['page-nav.nav-managementMenu', 'page-nav.nav-menuPropNavigation']],
  ['easyPayUsers', ['page.easyPayUsers.cyrillicTitle']],
  ['managementErrorCodes', ['page-nav.nav-dictionaryManagement', 'page-nav.nav-errorCodes']],
  ['partnerInfoSender', ['page-nav.nav-partnerInfoSender']],
  ['manageMerchantCallbacksGrid', ['page-nav.nav-manageMerchantCallbacks']],
  ['previewApplication', ['page-nav.nav-previewApplication']],
  ['reportByPaymentTypes', ['page-nav.nav-report', 'page-nav.tab-reportByPaymentTypes']],
  ['MFODictionary', ['page-nav.nav-dictionaryManagement', 'page-nav.tab-MFODictionary']],
  ['technicalPartners', ['page-nav.nav-dictionaryManagement', 'page-nav.nav-technicalPartners']],
  ['serviceSuppliers', ['page-nav.nav-serviceManagement', 'page-nav.nav-serviceSupplier']],
  ['beneficiaries', ['page-nav.nav-serviceManagement', 'page-nav.nav-beneficiaries']],
  ['directors', ['page-nav.nav-serviceManagement', 'page-nav.nav-directors']],
  ['fiscalizedTransactionsDaysReport', ['page-nav.nav-report', 'page-nav.nav-fiscalizedTransactionsDaysReport']],
  [
    'fiscalizedTransactionsOwnersDaysReport',
    ['page-nav.nav-report', 'page-nav.nav-fiscalizedTransactionsOwnersDaysReport'],
  ],
  ['externalOutgoingPayments', ['page-nav.nav-externalOutgoingPayments']],
]);
