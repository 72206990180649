import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import CommissionsServicesGrid from './CommissionsServicesGrid';
import { ApiService, tabService, StorageService } from '../../../services';
import CommissionsServicesService from './commissionsServices.service';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class CommissionsServicesGridContainer extends PureComponent {
  state = {
    isLoading: false,
    paymentTypes: [],
    profiles: [],
    services: [],
    selectOwner: 1,
    selectPaymentTypes: [],
    selectProfile: null,
    selectServices: [],
    commissionsClientServices: [],
    dialogType: '',
    selectedItems: [],
    owners: [],
    isLastOperationEnded: true,
    copyCommissionResultList: [],
    currentBatchId: null,
    copyCommissionProcessPercentMessage: '',
  };

  async componentDidMount() {
    window.addEventListener('beforeunload', this.saveSelectValues);

    this.setState({
      selectOwner: StorageService.getItem('externalCommissionsServicesGridOwner') || 1,
      selectPaymentTypes: StorageService.getItem('externalCommissionsServicesPaymentTypes') || [],
      selectServices: StorageService.getItem('externalCommissionsServicesGridServices') || [],
      selectProfile: StorageService.getItem('externalCommissionsServicesGridProfile') || null,
    });

    await this.fetchByQueryPath();
  }

  saveSelectValues = () => {
    const { selectOwner, selectPaymentTypes, selectServices, selectProfile } = this.state;
    StorageService.setItem('externalCommissionsServicesGridOwner', selectOwner);
    StorageService.setItem('externalCommissionsServicesPaymentTypes', selectPaymentTypes);
    StorageService.setItem('externalCommissionsServicesGridServices', selectServices);
    StorageService.setItem('externalCommissionsServicesGridProfile', selectProfile);
  };

  componentWillUnmount() {
    this.saveSelectValues();
    window.removeEventListener('beforeunload', this.saveSelectValues);
  }

  fetchByQueryPath = async () => {
    const {
      history: {
        location: { state: redirectState },
      },
    } = this.props;

    if (redirectState) {
      await this.searchDataAfterPush(redirectState);
    } else {
      await this.getSelectsData();
    }
  };

  searchDataAfterPush = async (redirectState) => {
    const { ProfileId, OwnerId, PaymentType } = redirectState;

    await this.setState({ selectOwner: OwnerId, selectProfile: ProfileId, selectPaymentTypes: [PaymentType] });
    await this.getSelectsData();

    this.setState(
      ({ services }) => {
        return {
          selectProfile: ProfileId,
          selectPaymentTypes: [PaymentType],
          selectServices: services.length ? services.map(({ ServiceId }) => ServiceId) : [],
        };
      },
      () => {
        this.getCommissionsClientServices();
      },
    );
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      await this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    }
  };

  getOwners = async () => {
    try {
      const data = await api.owners();

      await this.setState({ owners: data });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    }
  };

  getProfiles = async () => {
    const { selectOwner } = this.state;
    const { translate } = this.props;

    try {
      const data = await api.postProfileList({
        ownerId: [selectOwner],
      });

      await this.setState({
        profiles: data && data.length ? DropdownNormalizersService.normalizeProfiles(data, translate) : [],
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };

  getServices = async () => {
    const { selectOwner } = this.state;

    try {
      const data = await api.getServiceDictionary({
        ownerId: [selectOwner],
      });

      await this.setState({ services: data && data.length ? DropdownNormalizersService.normalizeServices(data) : [] });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    }
  };

  getSelectsData = async () => {
    await this.toggleLoader(true);

    await this.getOwners();
    await this.getPaymentType();
    await this.getProfiles();
    await this.getServices();
    await this.getCopyCommissionStatus();

    await this.toggleLoader(false);
  };

  getCopyCommissionStatus = async () => {
    const { translate } = this.props;
    try {
      const copyCommissionStatusList = await api.commissionsClientReferenceCopyRequests();
      if (!copyCommissionStatusList || !copyCommissionStatusList.length) {
        this.setState({ isLastOperationEnded: true });

        return;
      }

      const lastBatch = copyCommissionStatusList.sort(
        ({ DateCreated: prevDateCreated }, { DateCreated: nextDateCreated }) =>
          new Date(nextDateCreated) - new Date(prevDateCreated),
      )[0];

      if (!lastBatch.CountInProcess || !lastBatch.CountTotal) {
        this.setState({ isLastOperationEnded: true, currentBatchId: lastBatch });

        return;
      }

      await this.setState({
        isLastOperationEnded: false,
        copyCommissionProcessPercentMessage: CommissionsServicesService.getPercent(
          lastBatch.CountInProcess,
          lastBatch.CountTotal,
          translate,
        ),
      });
    } catch (error) {
      const { showError } = this.props;

      showError(error);
    }
  };

  getCommissionsClientServices = async () => {
    try {
      const { selectOwner, selectProfile, selectPaymentTypes, selectServices } = this.state;
      await this.toggleLoader(true);

      const data = await api.getCommissionsClientServices({
        ownerId: selectOwner,
        profileId: selectProfile,
        paymentTypesList: selectPaymentTypes,
        servicesList: selectServices,
      });

      await this.setState({
        commissionsClientServices: CommissionsServicesService.normalizeCommissionsClientServices(data),
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwnerChange = (ownerIdList) => {
    this.setState({ selectOwner: ownerIdList[0] }, async () => {
      await this.setState({ selectProfile: null, isLoading: true });
      await this.getProfiles();
      await this.setState({ isLoading: false });
    });
  };

  onPaymentTypesSave = (selectPaymentTypes) => {
    this.setState({ selectPaymentTypes });
  };

  onProfileSave = (profiles) => {
    this.setState({ selectProfile: profiles[0] });
  };

  onServiceSave = (selectServices) => {
    this.setState({ selectServices });
  };

  deleteCommission = async (selectItems) => {
    const { selectProfile, paymentTypes } = this.state;
    try {
      await this.toggleLoader(true);

      const model = selectItems.map(({ ServiceId, CommissionId, PaymentType }) => ({
        CommissionId,
        ServiceId,
        ProfileId: selectProfile,
        paymentTypesList: [PaymentType],
      }));

      const commissionActionsList = await api.deleteCommissionsClientReference(model);

      await this.getCommissionsClientServices();

      await tabService.addTab({
        type: 'commissionActionResults',
        dataItem: {
          commissionActionResults: commissionActionsList,
          paymentTypes,
        },
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  redirectToCommissionPage = ({ dataItem: { CommissionId } }) => {
    const { history } = this.props;

    return history.push({
      pathname: '/pages/managementCommissions/commission',
      state: { CommissionId },
    });
  };

  setDialogType = (dialogType) => {
    this.setState({ dialogType });
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  getCopyCommissionResult = async () => {
    const { currentBatchId, paymentTypes } = this.state;

    this.toggleLoader(true);

    try {
      if (!currentBatchId || !currentBatchId.BatchGuid) {
        await this.getCopyCommissionStatus();
        this.setDialogType('copyCommissionResult');
        return;
      }

      const commissionsActionList = await api.commissionsClientReferenceCopyResult(currentBatchId.BatchGuid);

      await tabService.addTab({
        type: 'commissionActionResults',
        dataItem: {
          commissionActionResults: commissionsActionList && commissionsActionList.length ? commissionsActionList : [],
          paymentTypes,
        },
      });
    } catch (e) {
      const { showError } = this.props;

      showError(e);
    } finally {
      await this.toggleLoader(false);
    }
  };

  onOpenCopyCommissionTab = () => {
    const { services, paymentTypes, profiles } = this.state;

    tabService.addTab({
      type: 'copyCommission',
      dataItem: {
        services,
        paymentTypes,
        profiles,
      },
    });
  };

  render() {
    const {
      isLoading,
      profiles,
      paymentTypes,
      selectPaymentTypes,
      selectProfile,
      services,
      selectServices,
      commissionsClientServices,
      selectOwner,
      dialogType,
      selectedItems,
      owners,
      isLastOperationEnded,
      currentBatchId,
      copyCommissionProcessPercentMessage,
    } = this.state;

    return (
      <CommissionsServicesGrid
        onOwnerChange={this.onOwnerChange}
        onPaymentTypesSave={this.onPaymentTypesSave}
        onProfileSave={this.onProfileSave}
        onServiceSave={this.onServiceSave}
        getCommissionsClientServices={this.getCommissionsClientServices}
        profiles={profiles}
        paymentTypes={paymentTypes}
        services={services}
        getSelectsData={this.getSelectsData}
        onSelect={this.handleSelectionChange}
        deleteCommission={this.deleteCommission}
        redirectToCommissionPage={this.redirectToCommissionPage}
        setDialogType={this.setDialogType}
        isLoading={isLoading}
        selectPaymentTypes={selectPaymentTypes}
        selectProfiles={[selectProfile]}
        selectServices={selectServices}
        selectOwner={selectOwner}
        currentBatchId={currentBatchId}
        commissionsClientServices={commissionsClientServices}
        dialogType={dialogType}
        selectedItems={selectedItems}
        owners={DropdownNormalizersService.normalizeOwners(owners)}
        isLastOperationEnded={isLastOperationEnded}
        getCopyCommissionResult={this.getCopyCommissionResult}
        copyCommissionProcessPercentMessage={copyCommissionProcessPercentMessage}
        onOpenCopyCommissionTab={this.onOpenCopyCommissionTab}
      />
    );
  }
}

export default withRouter(withTranslate(CommissionsServicesGridContainer));
