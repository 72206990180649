export default class StorageService {
  static clean = () => {
    // костыль, чистка кеша дилеров и фильтров
    const items = [
      'owners',
      'owners.selection',
      'DateRange',
      'access_token',
      'refresh_token',
      'main.selection',
      'constantUserOwnerId',
      'userInfo',
      'newMessagesCount',
    ];
    for (const item of items) {
      localStorage.removeItem(item);
    }
  };

  static getItem = (key) => {
    const internalItem = localStorage.getItem(key);

    if (internalItem === null) {
      return null;
    }

    const storageItem = JSON.parse(internalItem);

    return storageItem.value && JSON.parse(storageItem.value);
  };

  static setItem = (key, data) => {
    const value = JSON.stringify(data);
    const storageItem = {
      value: value,
      saveTime: Date.now(),
    };

    localStorage.setItem(key, JSON.stringify(storageItem));
  };

  static removeItem = (key) => {
    localStorage.removeItem(key);
  };
}
