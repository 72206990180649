import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import FilterActionBlock from './FilterActionBlock';
import { generateGUID } from '../../utils/generateGUID';

import './FilterConstructorModal.styled.scss';

class FilterConstructorModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterState: this.formatInitialFilter(this.props.filters),
    };
  }

  handleSave = () => {
    const { filterState } = this.state;
    const { onSave } = this.props;

    onSave(this.formatFilterItems(this.filterEmptyItems(filterState)));
  };

  formatInitialFilter = (group) => ({
    ...group,
    filters: group.filters.map((item) => {
      if (item && item.customFilterType === 'dateNull') {
        return {
          field: item.field,
          operator: item.operator === 'eq' ? 'isnull' : 'isnotnull',
          value: '',
          id: generateGUID(),
        };
      }

      if (item.logic && item.filters && item.filters.length) {
        return this.formatFilterItems(item);
      }

      return { ...item, id: generateGUID() };
    }),
    id: generateGUID(),
  });

  filterEmptyItems = (group) => ({
    ...group,
    filters: group.filters
      .filter((item) => {
        return item.logic || (item.field && item.operator);
      })
      .map((item) => (item.logic ? this.filterEmptyItems(item) : item))
      .filter((item) => !item.logic || item.filters.length),
  });

  formatFilterItems = (group) => ({
    ...group,
    filters: group.filters.map((item) => {
      if (item.logic && item.filters && item.filters.length) {
        return this.formatFilterItems(item);
      }

      const { fieldsInfo, isGQL } = this.props;

      if (
        !isGQL &&
        item &&
        (item.operator === 'isnull' || item.operator === 'isnotnull') &&
        fieldsInfo[item.field].filter === 'date'
      ) {
        return {
          customFilterType: 'dateNull',
          field: item.field,
          operator: item.operator === 'isnull' ? 'eq' : 'neq',
          value: new Date(null),
          id: item.id,
        };
      }

      return item;
    }),
  });

  render() {
    const { fieldsInfo, translate, open, onClose } = this.props;
    const { filterState } = this.state;

    return (
      <Modal
        className="FilterConstructorModal"
        visible={open}
        title={translate('page.filterConstructorModal.title')}
        onCancel={onClose}
        footer={[
          <Button type="primary" key="add" onClick={this.handleSave}>
            {translate('core.buttonTitles.save')}
          </Button>,
          <Button key="back" onClick={onClose}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <FilterActionBlock
          data={filterState}
          fieldsInfo={fieldsInfo}
          removeAction={() => {
            this.setState({
              filterState: { logic: 'and', filters: [], id: '0' },
            });
          }}
          updateData={(data) => {
            this.setState({
              filterState: data,
            });
          }}
        />
      </Modal>
    );
  }
}

export default withTranslate(FilterConstructorModal);
