const manageMerchantCallbacksGrid = (translate, value) => {
  switch (value) {
    case 'manageMerchantCallbacksGrid-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'manageMerchantCallbacksGrid-saveEdit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.save`)}`;
    case 'manageMerchantCallbacksGrid-delete':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.delete`)}`;
    case 'manageMerchantCallbacksGrid-addEdit':
      return `${translate(`page.adminPanel.type-button`)}: ${translate(`core.buttonTitles.add`)}`;
    default:
      return value;
  }
};

export default manageMerchantCallbacksGrid;
